import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthUserContext from './context';
import { firebaseAuth } from '../../server/config';
import { LANDING } from '../../config/routes';

const withAuthorization = (condition, prevRoute = null) => (Component) => {
  function WithAuthorization(props) {
    useEffect(() => {
      firebaseAuth().onAuthStateChanged((authUser) => {
        if (!condition(authUser)) {
          props.history.push({ pathname: LANDING });
          console.log(authUser);
          console.log(prevRoute);
          //  props.history.push({ pathname: SIGN_IN, search: prevRoute ? `?from=${prevRoute}` : '' });
        }
      });
      // write remove listener
    }, [props]);

    return (
      <AuthUserContext.Consumer>
        {(authUser) => condition(authUser) ? <Component {...props} user={authUser} /> : null}
      </AuthUserContext.Consumer>
    );
  }

  WithAuthorization.propTypes = {
    history: PropTypes.object,
  };

  return withRouter(WithAuthorization);
};

export default withAuthorization;
