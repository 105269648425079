import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import TuneIcon from '@material-ui/icons/Tune';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Album from '../../components/Album';
import FilterPane from '../../components/FilterPane';
import {
  getLabel, processUrl, getCarName, createProductUrl,
} from '../../helpers/utility';
import {
  fireAllQueries, fireMakeModelQuery, resetPLP, loadMorePLPCars, updateMakeModelFilters, updateOtherFilters, setPriceFilter, removePLPCars, removePLPCarMake,
} from './actions';
import {
  updateProductDetails,
} from '../ProductDetailPage/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  drawerPaper: {
    paddingTop: theme.spacing(2),
    minWidth: 210,
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  filterButton: {
    margin: theme.spacing(1, 4),
  },
  headText: {
    marginTop: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  listUnit: {
    paddingTop: theme.spacing(1),
  },
  tabs: {
    margin: theme.spacing(0, 3),
  },
  chip: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  toolbar: theme.mixins.toolbar,
}));

function ProductListingPage(props) {
  const { location, history } = props;
  const classes = useStyles();
  const [openFilter, setFilterOpen] = useState(false);
  const [brand, setBrand] = useState(false);
  const [offerType, setOfferType] = useState('subscribe');

  const dispatch = useDispatch();
  const productListing = useSelector((state) => state.productListing);
  const {
    cars, filters, subQueries, noMoreCars,
  } = productListing;
  // console.log(productListing);

  useEffect(() => {
    const {
      make = '', model = '', price = [], type = 'subscribe',
    } = processUrl(location, 'listing');

    dispatch(resetPLP());

    if (type === 'sale') {
      dispatch(updateOtherFilters('isSale', true, true));
    } else {
      dispatch(updateOtherFilters('isSubscribe', true, true));
    }
    setOfferType(type);

    if (price.length === 2) {
      setPrice('subscribePrice', price);
    } else if (make === 'all') {
      dispatch(fireAllQueries());
      setBrand(make);
    } else if (make !== model) {
      dispatch(fireMakeModelQuery(make, [{ field: 'makeModel', operator: '==', filterValue: model }]));
      dispatch(updateMakeModelFilters(make, model, true));
      setBrand(make);
    } else {
      dispatch(fireMakeModelQuery(make, [{ field: 'makeKey', operator: '==', filterValue: make }]));
      dispatch(updateMakeModelFilters(make, model, true));
    }
    window.scrollTo(0, 0);
  }, [dispatch, location]);


  // subKey is the primary key
  const setFilters = (field, subKey, toggle) => {
    if (field === 'make') {
      dispatch(fireAllQueries(subKey, toggle));
    } else if (toggle === true) {
      dispatch(updateOtherFilters(field, subKey, toggle));
    } else handleDelete({ key: subKey, field })();
  };

  const handleDelete = (chipToDelete) => () => {
    const { key, field } = chipToDelete;
    dispatch(updateOtherFilters(field, key, false));
  };

  const setPrice = (mainKey, slideValue) => dispatch(setPriceFilter(mainKey, slideValue));

  const removeCarModel = (makeName, subKey) => dispatch(removePLPCars(makeName, subKey));

  const removeCarMake = (makeName) => dispatch(removePLPCarMake(makeName));

  const updateMakeModel = (makeName, subKey, toggle) => dispatch(updateMakeModelFilters(makeName, subKey, toggle));

  const loadMore = () => dispatch(loadMorePLPCars());

  const navigateToDetails = (e, data) => {
    let newTab = false;
    if (e) {
      e.stopPropagation();
      newTab = e.ctrlKey || e.metaKey;
    }
    if (newTab) {
      window.open(`/products/${createProductUrl(data)}`, '_blank');
    } else {
      history.push(`/products/${createProductUrl(data)}`);
    }
    dispatch(updateProductDetails(data));
  };

  const setMakeModelQuery = (makeName, queryData) => {
    dispatch(fireMakeModelQuery(makeName, queryData));
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const listData = cars.all || [''];

  const excludeArr = ['isSale', 'isSubscribe', 'subscribePrice'];
  const excludeFilters = ['isSale', 'isSubscribe'];

  const filterDrawer = () => (
    <>
      <Typography gutterBottom align="center" variant="h6">
        Filters
      </Typography>
      {subQueries.length > 0 && subQueries.filter((item) => !excludeArr.includes(item.field)).map((item) => (
        <Chip
          key={item.key}
          variant="outlined"
          label={(
            <>
              <b>{getLabel(item.field)}</b>
              {`: ${getLabel(item.key)}`}
            </>
          )}
          onDelete={handleDelete(item)}
          className={classes.chip}
          color="primary"
        />
      ))}
      <br />
      {Object.keys(filters).filter((item) => !excludeFilters.includes(item)).map((item) => (
        <FilterPane
          key={item}
          name={item}
          filter={filters[item]}
          setFilters={setFilters}
          removeCarModel={removeCarModel}
          updateMakeModel={updateMakeModel}
          removeCarMake={removeCarMake}
          setMakeModelQuery={setMakeModelQuery}
          setPriceFilter={setPrice}
        />
      ))}
    </>
  );

  return (
    <>
      <NavBar />
      <main>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          <Hidden smDown>
            <Grid item md={2}>
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.toolbar} />
                {filterDrawer()}
              </Drawer>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={10} className={classes.listUnit}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Hidden mdUp>
                <Button variant="outlined" className={classes.filterButton} onClick={() => setFilterOpen(true)}>
                  <TuneIcon className={classes.leftIcon} />
                  Filters
                </Button>
              </Hidden>
              <Typography variant="h5" noWrap className={classes.headText}>
                {`${getCarName(brand) || ''} Cars in Bengaluru`}
              </Typography>
            </Grid>
            <Album
              data={cars.all}
              offerType={offerType}
              navigateToDetails={navigateToDetails}
              removeCarModel={removeCarModel}
              updateMakeModel={updateMakeModel}
              removeCarMake={removeCarMake}
            />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              { (listData.length % 9 === 0) && !noMoreCars && (
                <Button variant="outlined" className={classes.filterButton} color="primary" onClick={() => loadMore()}>
                  Load More...
                </Button>
              )}
              {noMoreCars && (
                <Typography variant="subtitle1" color="textSecondary" className={classes.headText}>
                  That&#39;s all folks!
                </Typography>
              )}
            </Grid>
            <Footer />
          </Grid>
        </Grid>
        <Hidden smUp implementation="css">
          <Dialog
            fullScreen
            open={openFilter}
            onClose={handleFilterClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="lg"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DialogTitle id="responsive-dialog-title" disableTypography className={classes.title}>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleFilterClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {filterDrawer()}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilterClose} color="primary">
                Close
              </Button>
              <Button onClick={handleFilterClose} color="primary" autoFocus>
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </main>
    </>
  );
}

ProductListingPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default ProductListingPage;
