import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withAuthorization } from '../../common/Session';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
  },
  layout: {
    minHeight: '70vh',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 15),
    },
  },
}));

function AddressForm(props) {
  const { onSubmit, user, data = {} } = props;
  const classes = useStyles();

  useEffect(() => {
    const {
      firstName = '', lastName = '', dob = '', email = '', address: {
        address1 = '', address2 = '', zip = '',
      } = {},
    } = data;
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('dob', dob);
    setValue('email', email);
    setValue('address.address1', address1);
    setValue('address.address2', address2);
    setValue('address.zip', zip);
  }, [data]);

  const {
    register, handleSubmit, errors, setValue,
  } = useForm();

  const formSubmit = (datum) => { onSubmit(datum); };

  return (
    <div className={classes.layout}>
      <Typography variant="h5" align="center" className={classes.section} gutterBottom>
        Profile
      </Typography>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="fname"
              inputRef={register({ required: true, maxLength: 40 })}
            />
            <Typography variant="body2" color="error">{errors.firstName && 'Please ensure name is entered'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="lname"
              inputRef={register({ maxLength: 40 })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="dob"
              name="dob"
              label="Date of Birth"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              inputRef={register({ required: true })}
            />
            <Typography variant="body2" color="error">{errors.dob && 'Please ensure correct DOB is entered'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="phone"
              value={user.phoneNumber}
              InputProps={{
                readOnly: true,
              }}
              helperText="account should have unique phone number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="email"
              inputRef={register({ pattern: /^\S+@\S+$/i })}
            />
            <Typography variant="body2" color="error">{errors.email && 'Your input should be in email format'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Typography variant="h5" align="center" className={classes.section} gutterBottom>
          Address
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address.address1"
              label="Address line 1"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="billing address-line1"
              inputRef={register({ required: true })}
            />
            <Typography variant="body2" color="error">{errors.address && errors.address.address1 && 'Please ensure correct Address line is entered here'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address.address2"
              label="Address line 2"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="billing address-line2"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="address.city"
              label="City"
              defaultValue="Bangalore"
              fullWidth
              inputRef={register({ required: true })}
            />
            <Typography variant="body2" color="error">{errors.address && errors.address.city && 'Please ensure correct City is entered here'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              name="address.state"
              label="State/Province/Region"
              defaultValue="Karnataka"
              fullWidth
              inputRef={register({ required: true })}
            />
            <Typography variant="body2" color="error">{errors.address && errors.address.city && 'Please ensure correct State is entered here'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="address.zip"
              label="Zip / Postal code"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: true, maxLength: 6 })}
            />
            <Typography variant="body2" color="error">{errors.address && errors.address.zip && 'Please ensure correct PIN code is entered here'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid item container xs={12} justify="flex-end">
          <Button variant="contained" size="large" className={classes.button} onClick={handleSubmit(onSubmit)} color="primary">
            Submit
          </Button>
        </Grid>
      </form>
    </div>
  );
}

AddressForm.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.object,
  data: PropTypes.object,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AddressForm);
