import { UPDATE_ACCOUNT, HYDRATE_ACCOUNT } from '../../config/constants';

const initialState = {};

export default function reducer(state = initialState, action) {
  const {
    type, data, key, value,
  } = action;

  switch (type) {
    case HYDRATE_ACCOUNT:
      return Object.assign({}, state, data);
    case UPDATE_ACCOUNT:
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
}
