import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDnt97xbuGv-i6x4EL5ymPgLj5vsQcfQsU',
  authDomain: 'tiltshift-main.firebaseapp.com',
  databaseURL: 'https://tiltshift-main.firebaseio.com',
  projectId: 'tiltshift-main',
  storageBucket: 'tiltshift-main.appspot.com',
  messagingSenderId: '736071616625',
  appId: '1:736071616625:web:79a6a2f01b9c0bd50273d0',
  measurementId: 'G-EYLF1NG63Z',
};
firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;
export const database = firebase.firestore();
export const functions = firebase.app().functions('asia-east2');
export const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const increment = firebase.firestore.FieldValue.increment(1);
export const arrayUnion = (myArray) => firebase.firestore.FieldValue.arrayUnion.apply(this, myArray);
export const storage = firebase.storage();
export const analytics = firebase.analytics();

// Enable offline support
firebase.firestore().enablePersistence({ synchronizeTabs: true }).catch((err) => {
  if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    console.log(err);
  }
});
