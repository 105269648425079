import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MaskedInput from 'react-text-mask';
import { firebaseAuth, analytics } from '../../server/config';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: theme.spacing(2, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 2),
    flexGrow: 1,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '9', '1', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function LoginPage(props) {
  const { onCancel = null } = props;
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verifCode, setVerifCode] = useState('');
  const [enableVerify, setEnableVerify] = useState(true);
  const [nextStep, setNextStep] = useState(false);
  const [verifId, setVerifId] = useState();
  const captchaEl = useRef(null);

  useEffect(() => {
    // firebaseAuth().settings.appVerificationDisabledForTesting = true;
    captchaEl.current = new firebaseAuth.RecaptchaVerifier('recaptcha-container', {
      size: 'normal',
      callback: (response) => {
        console.log(response);
        setEnableVerify(true);
      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    });
    captchaEl.current.render().then((widgetId) => {
      captchaEl.current.reset(widgetId);
    });
  }, []);

  const handlePhoneVerify = () => {
    const provider = new firebaseAuth.PhoneAuthProvider();

    provider.verifyPhoneNumber(phoneNumber, captchaEl.current).then((verificationId) => {
      setVerifId(verificationId);
      setNextStep(true);
    }).catch((error) => {
      console.log(error);
    });
  };

  const handlePhoneLogin = () => {
    const phoneCredential = firebaseAuth.PhoneAuthProvider.credential(verifId, verifCode);
    firebaseAuth().signInWithCredential(phoneCredential).then(() => {
      onCancel();
      analytics.logEvent('login');
    })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Sign in
        </Typography>
        <br />
        {!nextStep && (
          <>
            <Typography variant="h6">
              Enter your phone number
            </Typography>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
              <Input id="phoneNumber" value={phoneNumber} onChange={(evt) => setPhoneNumber(evt.target.value)} name="phoneNumber" inputComponent={TextMaskCustom} autoFocus />
            </FormControl>
            <div id="recaptcha-container"></div>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!enableVerify}
                onClick={() => handlePhoneVerify()}
              >
                Verify
              </Button>
            </div>
            <Typography variant="caption" align="justify" gutterBottom>
              By tapping Verify, an SMS may be sent. Message & data rates may apply
            </Typography>
          </>
        )}
        {nextStep && (
          <>
            <Typography variant="h6" gutterBottom>
              Verify your phone number
            </Typography>
            <Typography display="inline" variant="subtitle1">
              {'Please enter the 6-digit code we sent to '}
            </Typography>
            <Typography display="inline" color="textSecondary" variant="subtitle1">{phoneNumber}</Typography>
            <TextField
              id="verification-number"
              label="6-digit code"
              value={verifCode}
              onChange={(evt) => setVerifCode(evt.target.value)}
              margin="normal"
            />
            <div className={classes.buttons}>
              <Button
                variant="contained"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!enableVerify}
                onClick={() => handlePhoneLogin()}
              >
                Verify
              </Button>
            </div>
            <Typography variant="caption" align="justify" gutterBottom>
              By signing in, you agree to our
              {' '}
              <Link href="https://tylt.co.in/terms" color="primary" target="_blank">Terms</Link>
              {' and '}
              <Link href="https://tylt.co.in/privacy" color="primary" target="_blank">Privacy Policy</Link>
              . You also agree you are over 18 years of age.
            </Typography>
          </>
        )}
      </Paper>
    </main>
  );
}

LoginPage.propTypes = {
  onCancel: PropTypes.func,
};

export default LoginPage;
