import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WebIcon from '@material-ui/icons/Web';
import DriveEta from '@material-ui/icons/DriveEta';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import CreditCard from '@material-ui/icons/CreditCard';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Replay from '@material-ui/icons/Replay';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Block from '@material-ui/icons/Block';
import Star from '@material-ui/icons/Star';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import SecurityIcon from '@material-ui/icons/Security';
import NavBar from '../../components/NavBar';
import PaymentPage from '../../components/PaymentPage';
import Footer from '../../components/Footer';
import Icon from '../../components/Icon';
import carImg from '../../images/carImg.png';
import sCross from '../../images/sCross.jpg';
import i10 from '../../images/i10.jpg';
// import banner2 from '../../images/banner2.jpg';
import makeLogo from '../../images/logo.png';
import mobilePic from '../../images/mobilePic.jpg';
// import { createProductUrl } from '../../helpers/utility';
// import { updateProductDetails } from '../ProductDetailPage/actions';
import { LISTINGS, JOIN } from '../../config/routes';
import { setDocData, getDocData } from '../../server/database';
import { increment, timestamp, analytics } from '../../server/config';


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  hero: {
    minHeight: '20vh',
    width: '100%',
    padding: theme.spacing(6, 2),
    backgroundColor: theme.palette.background.paper,
    // color: theme.palette.primary.contrastText,
  },
  banner2: {
    minHeight: 700,
    padding: theme.spacing(5),
    margin: 'auto',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  innerbanner1: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  innerBanner2: {
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
    width: 600,
    maxWidth: '80vw',
    backgroundColor: 'white',
  },
  innerBanner3: {
    padding: theme.spacing(3, 5),
    width: '100%',
    backgroundColor: 'white',
  },
  innerBanner4: {
    padding: theme.spacing(4, 1),
    width: '100%',
  },
  innerBanner5: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(4, 1),
    paddingLeft: theme.spacing(3),
    width: '100%',
    backgroundColor: 'white',
  },
  howItSvg: {
    fontSize: 72,
    paddingLeft: theme.spacing(1),
  },
  logo: {
    height: 48,
    marginBottom: theme.spacing(1),
  },
  carSubs: {
    fontSize: 42,
    fontWeight: 500,
    padding: theme.spacing(6, 0),
  },
  widthSetter: {
    maxWidth: 960,
    margin: 'auto',
  },
  buyGrid: {
    marginBottom: theme.spacing(2),
  },
  joinNow: {
    width: 190,
    height: 50,
  },
  bannerChild: { fontWeight: 500, paddingLeft: theme.spacing(1) },
  primText: {
    color: '#41946c',
  },
  carImg: {
    width: 500,
    maxWidth: '90vw',
  },
  innerHero: {
    fontSize: '18px',
    margin: theme.spacing(3, 0),
    marginRight: theme.spacing(1),
  },
  seeAll: {
    padding: theme.spacing(1, 2), fontSize: '1.1rem', textTransform: 'none', fontWeight: 500, borderRadius: 10,
  },
  home: {
    minHeight: '20vh',
    width: '100%',
    padding: theme.spacing(6, 2),
    // backgroundColor: theme.palette.primary.dark,
  },
  blackBanner: {
    width: '100%',
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    fontWeight: 550,
  },
  howItIcon: {
    borderRadius: '50%',
    border: '2px solid',
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  crossImg: {
    paddingTop: theme.spacing(2),
    maxWidth: 400,
    width: '80vw',
  },
  i10Img: {
    paddingTop: theme.spacing(2),
    maxWidth: 300,
    width: '60vw',
    maxHeight: 300,
  },
}));

const subsGridList = ['Zero Downpayment', 'Free periodic maintenance', 'Insurance included', 'No loan required - credit worthiness intact', 'No long term commitment', 'Return / Swap anytime'];

function HomePage(props) {
  const { history } = props;

  const classes = useStyles();
  const pricingRef = useRef(null);

  // const home = useSelector((state) => state.home);
  const [job, setJob] = useState('professional');
  const [loader, setLoader] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const {
    register, handleSubmit, errors, reset,
  } = useForm();

  const handleChange = (event) => {
    setJob(event.target.value);
  };

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const counter = await getDocData('leads', '--counter--');
      const docId = `deal0${counter.count}`;
      data.createdAt = timestamp;
      data.job = job;
      data.status = 'open';
      const setLeads = await setDocData('leads', docId, data);
      if (setLeads === 'Success') {
        setOpenSnack(true);
        reset({});
        await setDocData('leads', '--counter--', { count: increment });
      } else {
        console.log(setLeads);
      }
    } catch {
      console.log('error');
    }
    setLoader(false);
  };

  const navigateToList = () => {
    history.push(`${LISTINGS}/all/bengaluru`);
  };

  const navigateToJoin = (promotionName) => {
    analytics.logEvent('select_promotion', { promotion_name: promotionName });
    history.push(`${JOIN}`);
  };

  // const navigateToListPrice = (label, index) => {
  //   const match = label.match(/(\d+)/);
  //   if (index === 3) {
  //     history.push(`${LISTINGS}/all/above-${match[0]}/bengaluru`);
  //   } else history.push(`${LISTINGS}/all/under-${match[0]}/bengaluru`);
  // };

  return (
    <main className={classes.root}>
      <NavBar noBorder pricingRef={pricingRef} />
      {/* Hero unit */}
      {/* <Carousel autoPlay infinite> */}
      {/* { HeroUnits.map((item) => React.createElement(item))} */}
      {/* </Carousel> */}
      <div className={classes.hero}>
        <div className={classes.widthSetter}>
          <Grid
            container
            direction="row-reverse"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <img src={carImg} className={classes.carImg} alt="tilt car" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              container
              direction="column"
              justify="space-evenly"
              alignItems="flex-start"
            >
              <Typography variant="h4" component="h2" style={{ fontWeight: 500 }}>
                Get your dream car
                <br />
                at a fraction of the cost
              </Typography>
              <Typography variant="subtitle1" gutterBottom color="textSecondary" className={classes.innerHero}>
                monthly packages that
                include taxes, insurance and maintenance
              </Typography>
              <br />
              <Button variant="contained" color="primary" className={classes.seeAll} onClick={() => navigateToList()} disableElevation>
                Find your car
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.innerbanner1}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <CalendarTodayIcon color="primary" />
              <Typography variant="subtitle1" className={classes.bannerChild} gutterBottom>
                flexible monthly plans
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Typography variant="h6" color="primary" style={{ fontWeight: 800, fontSize: 17 }}>
                %
              </Typography>
              <Typography variant="subtitle1" className={classes.bannerChild} gutterBottom>
                0 downpayment
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <LoyaltyIcon color="primary" />
              <Typography variant="subtitle1" className={classes.bannerChild} gutterBottom>
                transparent pricing
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <SecurityIcon color="primary" />
              <Typography variant="subtitle1" className={classes.bannerChild} gutterBottom>
                quality cars
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.banner2}>
        <div className={classes.widthSetter}>
          <Paper className={classes.innerBanner2} elevation={2}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <img src={makeLogo} className={classes.logo} alt="logo" />
              <Typography variant="h4" component="h3" gutterBottom style={{ fontWeight: 500 }}>
                Hi, we&#39;re
                {' '}
                <span className={classes.primText}>TYLT</span>
              </Typography>
              <Typography variant="h3" component="h1" align="center" className={classes.carSubs} gutterBottom>
                Car subscription in Bangalore
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                Flexible monthly subscriptions for an all inclusive price. Option to return, upgrade or buy anytime.
              </Typography>
              <br />
              <Button variant="contained" color="primary" className={classes.seeAll} onClick={() => navigateToList()} disableElevation>
                Browse Cars
              </Button>
              <br />
              <Typography variant="subtitle1" align="center" gutterBottom>
                Starting at Rs 9,999/month
              </Typography>
            </Grid>
          </Paper>
        </div>
      </div>
      <div className={classes.hero}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography variant="h4" component="h2" align="center" style={{ fontWeight: 500 }}>
            What is
            {' '}
            <span className={classes.primText}>TYLT</span>
            {' '}
            Subscription?
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" style={{ paddingTop: 16, paddingBottom: 24, maxWidth: 500 }}>
            TYLT subscription is an easy and cost effective way to own a car without buying it.
            <br />
            Here&#39;s why
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.innerBanner3}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="h5" className={classes.primText} style={{ fontWeight: 500 }} gutterBottom>
                No commitments
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Our flexible plans start from 1 to 12 months. You are free to return, extend or upgrade.
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.primText} style={{ fontWeight: 500 }} gutterBottom>
                No downpayment
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                We only charge a small deposit. You can save the downpayment and put it to better use.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} justify="center" container><img src={mobilePic} style={{ height: 400 }} alt="mobile" loading="lazy" /></Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" className={classes.primText} style={{ fontWeight: 500 }} gutterBottom>
                You are covered
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Insurance and periodic maintenance are included in the monthly payment. You can rest easy, knowing you are covered.
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.primText} style={{ fontWeight: 500 }} gutterBottom>
                Credit worthiness intact
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                No loan is required to own a TYLT car. Your borrowing capacity will stay intact.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.hero}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography variant="h4" component="h2" align="center" style={{ fontWeight: 500 }} gutterBottom>
            How
            {' '}
            <span className={classes.primText}>TYLT</span>
            {' '}
            subscription works
          </Typography>
          <br />
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.innerBanner4}
            spacing={5}
          >
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              justify="space-around"
              alignItems="flex-start"
              spacing={2}
              style={{ minHeight: 400 }}
            >
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.howItIcon}>1</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Create an account
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      It takes just 2 minutes!
                    </Typography>
                  </Hidden>
                </Grid>
                <WebIcon className={classes.howItSvg} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <div className={classes.howItIcon}>2</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Pick a car
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      Pick your favourite car and raise a booking request.
                    </Typography>
                  </Hidden>
                </Grid>
                <DriveEta className={classes.howItSvg} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <div className={classes.howItIcon}>3</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Processing
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      We will do basic KYC verification and get your car ready.
                    </Typography>
                  </Hidden>
                </Grid>
                <VerifiedUser className={classes.howItSvg} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              justify="space-around"
              alignItems="flex-start"
              spacing={2}
              style={{ minHeight: 400 }}
            >
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.howItIcon}>4</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Delivery & payment
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      Delivery will be made in 5 days. The first payment with deposit needs to be made then.
                    </Typography>
                  </Hidden>
                </Grid>
                <CreditCard className={classes.howItSvg} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <div className={classes.howItIcon}>5</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Hassle free ownership
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      Leave all car worries to us. We provide periodic maintenance.
                    </Typography>
                  </Hidden>
                </Grid>
                <ThumbUp className={classes.howItSvg} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <div className={classes.howItIcon}>6</div>
                <Grid item xs>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    Return anytime
                  </Typography>
                  <Hidden xsDown>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                      If you are done using the car, cancel anytime.
                    </Typography>
                  </Hidden>
                </Grid>
                <Replay className={classes.howItSvg} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div
        className={classes.home}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography className={classes.blackBanner} align="center" variant="h4" component="h2" gutterBottom>
            THE EARLY BIRD GETS THE BEST OFFER
          </Typography>
          <br />
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={8} sm={5}>
              <Typography align="left" variant="h5" gutterBottom style={{ fontWeight: 500 }}>
                Join today and earn
                <span className={classes.primText}>{' Rs 2,000 '}</span>
                credit in your TYLT account.
              </Typography>
            </Grid>
            <Icon icon="dove" viewBox="0 0 1044 1280" height={160} />
            <br />
          </Grid>
          <Grid item sm={5}>
            <Typography align="left" variant="h4" gutterBottom style={{ fontWeight: 500 }}>
              Don&#39;t miss out
            </Typography>
            <br />
          </Grid>
          <Button variant="contained" size="large" className={classes.joinNow} onClick={() => navigateToJoin('early_bird')} color="secondary" disableElevation>
            Join Today
          </Button>
        </Grid>
      </div>
      <div className={classes.hero}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography variant="h4" component="h2" align="center" gutterBottom style={{ fontWeight: 500 }}>
            <span className={classes.primText}>Subscription</span>
            {' '}
            vs Buying?
          </Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.innerBanner5}
          >
            <Grid container style={{ marginBottom: 24 }}>
              <Grid
                item
                xs={6}
              >
                <Typography variant="h5" align="left" style={{ fontWeight: 600 }} gutterBottom>
                  Comparison
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <Typography variant="h5" align="center" className={classes.primText} style={{ fontWeight: 600 }} gutterBottom>
                  TYLT
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <Typography variant="h5" align="center" style={{ fontWeight: 500 }} gutterBottom>
                  Buying
                </Typography>
              </Grid>
            </Grid>
            {subsGridList.map((item) => (
              <Grid key={item} container className={classes.buyGrid}>
                <Grid
                  item
                  xs={6}
                >
                  <Typography align="left" variant="h6" color="textSecondary" gutterBottom>
                    {item}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  xs={3}
                >
                  <CheckCircle fontSize="large" color="primary" />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  xs={3}
                >
                  <Block fontSize="large" color="disabled" />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.home}
        ref={pricingRef}
      >
        <Typography variant="h4" component="h3" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 300 }} align="center" component="h5" gutterBottom>
          TYLT payment plans are flexible and can vary
          <br />
          based on your convenience.
        </Typography>
        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <img src={sCross} className={classes.crossImg} alt="tilt car" loading="lazy" />
          <PaymentPage home />
          <Button variant="contained" color="primary" disableElevation className={classes.seeAll} onClick={() => navigateToList()}>
            Best Car Deals
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div
        className={classes.home}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography variant="h4" component="h3" gutterBottom>
            {'More '}
            <span className={classes.primText}>
              Offers
            </span>
          </Typography>
          <br />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="baseline"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="center" component="h5" gutterBottom style={{ padding: 16 }}>
                Working professionals can register and drive a car free, for a day.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} container alignItems="center" direction="column">
              <TextField
                id="primaryPhone"
                name="primaryPhone"
                label="Phone Number"
                defaultValue="+91"
                variant="outlined"
                fullWidth
                style={{ maxWidth: 350 }}
                inputRef={register({ required: true, maxLength: 13, validate: (value) => value.length === 13 || value.length === 10 })}
              />
              <Typography variant="body2" color="error">{errors.primaryPhone && 'Please ensure valid phone number is entered'}</Typography>
              <br />
              <Select
                label="Profession"
                id="working-select"
                variant="outlined"
                value={job}
                onChange={handleChange}
                fullWidth
                style={{ maxWidth: 350 }}
              >
                <MenuItem value="professional">Working Professional</MenuItem>
                <MenuItem value="self-employ">Self Employed</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              <div style={{ paddingTop: 20 }}>
                { loader ? <CircularProgress /> : (
                  <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)} color="primary" disableElevation>
                    get deal
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ padding: '56px 0px' }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" align="center" component="h5" gutterBottom style={{ padding: 16 }}>
                <span style={{ color: '#f2006c' }}>COLIVE</span>
                {' '}
                residents get an extra Rs 1,000 credit in their TYLT account on joining.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} container justify="center">
              <Button variant="contained" size="large" className={classes.joinNow} onClick={() => navigateToJoin('colive')} color="secondary" disableElevation>
                Join Today
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div
        className={classes.home}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.widthSetter}
        >
          <Typography variant="h4" component="h3" gutterBottom>
            What our subscribers have to say
          </Typography>
          <br />
          <Grid
            container
            direction="row-reverse"
            justify="center"
            alignItems="center"
          >
            <Grid container item xs={12} sm={6} justify="center">
              <img src={i10} loading="lazy" className={classes.i10Img} alt="review car" />
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: 16 }}>
              <Typography variant="h6" className={classes.primText} gutterBottom>
                I am very pleased with the car and the service. Thanks TYLT!
              </Typography>
              <br />
              <Typography variant="h6" color="textSecondary" style={{ paddingLeft: 16 }}>
                Apurv
              </Typography>
              <div style={{ paddingLeft: 8 }}>{[1, 2, 3, 4, 5].map((item) => <Star key={item} fontSize="large" style={{ color: '#FFD700' }} />)}</div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
      >
        <SnackbarContent
          message="We will call you back!"
        />
      </Snackbar>
    </main>
  );
}

HomePage.propTypes = {
  history: PropTypes.object,
};

export default HomePage;
