const carNames = {
  'maruti-suzuki': 'Maruti Suzuki',
  hyundai: 'Hyundai',
  mahindra: 'Mahindra',
  tata: 'Tata',
  toyota: 'Toyota',
  honda: 'Honda',
  bmw: 'BMW',
  ford: 'Ford',
  'mercedes-benz': 'Mercedes-Benz',
  kia: 'Kia',
  mg: 'MG',
  renault: 'Renault',
  audi: 'Audi',
  nissan: 'Nissan',
  volkswagen: 'Volkswagen',
  skoda: 'Skoda',
  isuzu: 'Isuzu',
  datsun: 'Datsun',
  mini: 'Mini',
  'force-motors': 'Force Motors',
  'aston-martin': 'Aston Martin',
  jaguar: 'Jaguar',
  jeep: 'Jeep',
  volvo: 'Volvo',
  lexus: 'Lexus',
  'land-rover': 'Land Rover',
  bentley: 'Bentley',
  porsche: 'Porsche',
  mitsubishi: 'Mitsubishi',
  fiat: 'Fiat',
  'rolls-royce': 'Rolls-Royce',
  ferrari: 'Ferrari',
  maserati: 'Maserati',
  lamborghini: 'Lamborghini',
  bugatti: 'Bugatti',
  dc: 'DC',
  'maruti-suzuki+baleno': 'Maruti Suzuki Baleno',
  'maruti-suzuki+ertiga': 'Maruti Suzuki Ertiga',
  'maruti-suzuki+swift': 'Maruti Suzuki Swift',
  'maruti-suzuki+vitara-brezza': 'Maruti Suzuki Vitara Brezza',
  'maruti-suzuki+wagon-r': 'Maruti Suzuki Wagon R',
  'maruti-suzuki+alto': 'Maruti Suzuki Alto',
  'maruti-suzuki+dzire': 'Maruti Suzuki Dzire',
  'maruti-suzuki+celerio': 'Maruti Suzuki Celerio',
  'maruti-suzuki+alto-k10': 'Maruti Suzuki Alto K10',
  'maruti-suzuki+eeco': 'Maruti Suzuki Eeco',
  'maruti-suzuki+ciaz': 'Maruti Suzuki Ciaz',
  'maruti-suzuki+ignis': 'Maruti Suzuki Ignis',
  'maruti-suzuki+s-cross': 'Maruti Suzuki S-Cross',
  'maruti-suzuki+omni': 'Maruti Suzuki Omni',
  'maruti-suzuki+celerio-x': 'Maruti Suzuki Celerio X',
  'maruti-suzuki+gypsy': 'Maruti Suzuki Gypsy',
  'hyundai+grand-i10': 'Hyundai Grand i10',
  'hyundai+elite-i20': 'Hyundai Elite i20',
  'hyundai+venue': 'Hyundai Venue',
  'hyundai+creta': 'Hyundai Creta',
  'hyundai+verna': 'Hyundai Verna',
  'hyundai+santro': 'Hyundai Santro',
  'hyundai+xcent': 'Hyundai Xcent',
  'hyundai+i20-active': 'Hyundai i20 Active',
  'hyundai+kona-electric': 'Hyundai Kona Electric',
  'hyundai+elantra': 'Hyundai Elantra',
  'hyundai+tucson': 'Hyundai Tucson',
  'hyundai+': 'Hyundai ',
  'mahindra+scorpio': 'Mahindra Scorpio',
  'mahindra+xuv300': 'Mahindra XUV300',
  'mahindra+bolero': 'Mahindra Bolero',
  'mahindra+thar': 'Mahindra Thar',
  'mahindra+xuv500': 'Mahindra XUV500',
  'mahindra+marazzo': 'Mahindra Marazzo',
  'mahindra+kuv100-nxt': 'Mahindra KUV100 NXT',
  'mahindra+tuv300': 'Mahindra TUV300',
  'mahindra+alturas-g4': 'Mahindra Alturas G4',
  'mahindra+xylo': 'Mahindra Xylo',
  'mahindra+e2o-plus': 'Mahindra e2o PLUS',
  'mahindra+verito': 'Mahindra Verito',
  'mahindra+nuvosport': 'Mahindra NuvoSport',
  'mahindra+tuv300-plus': 'Mahindra TUV300 PLUS',
  'mahindra+scorpio-getaway': 'Mahindra Scorpio Getaway',
  'mahindra+verito-vibe-cs': 'Mahindra Verito Vibe CS',
  'tata+harrier': 'Tata Harrier',
  'tata+nexon': 'Tata Nexon',
  'tata+tiago': 'Tata Tiago',
  'tata+tigor': 'Tata Tigor',
  'tata+safari-storme': 'Tata Safari Storme',
  'tata+hexa': 'Tata Hexa',
  'tata+zest': 'Tata Zest',
  'tata+nano': 'Tata Nano',
  'tata+sumo-gold': 'Tata Sumo Gold',
  'tata+bolt': 'Tata Bolt',
  'tata+tiago-nrg': 'Tata Tiago NRG',
  'tata+nano-genx': 'Tata Nano GenX',
  'tata+tiago-jtp': 'Tata Tiago JTP',
  'tata+tigor-jtp': 'Tata Tigor JTP',
  'toyota+innova-crysta': 'Toyota Innova Crysta',
  'toyota+glanza': 'Toyota Glanza',
  'toyota+fortuner': 'Toyota Fortuner',
  'toyota+etios-liva': 'Toyota Etios Liva',
  'toyota+platinum-etios': 'Toyota Platinum Etios',
  'toyota+yaris': 'Toyota Yaris',
  'toyota+corolla-altis': 'Toyota Corolla Altis',
  'toyota+etios-cross': 'Toyota Etios Cross',
  'toyota+camry': 'Toyota Camry',
  'toyota+land-cruiser-prado': 'Toyota Land Cruiser Prado',
  'toyota+land-cruiser': 'Toyota Land Cruiser',
  'toyota+prius': 'Toyota Prius',
  'honda+city': 'Honda City',
  'honda+amaze': 'Honda Amaze',
  'honda+civic': 'Honda Civic',
  'honda+wr-v': 'Honda WR-V',
  'honda+brio': 'Honda Brio',
  'honda+jazz': 'Honda Jazz',
  'honda+br-v': 'Honda BR-V',
  'honda+cr-v': 'Honda CR-V',
  'honda+accord': 'Honda Accord',
  'honda+land-cruiser-prado': 'Honda Land Cruiser Prado',
  'honda+land-cruiser': 'Honda Land Cruiser',
  'honda+prius': 'Honda Prius',
  'bmw+3-series': 'BMW 3 Series',
  'bmw+x1': 'BMW X1',
  'bmw+x7': 'BMW X7',
  'bmw+5-series': 'BMW 5 Series',
  'bmw+x5': 'BMW X5',
  'bmw+3-series-gt': 'BMW 3 Series GT',
  'bmw+z4': 'BMW Z4',
  'bmw+x3': 'BMW X3',
  'bmw+x4': 'BMW X4',
  'bmw+6-series-gt': 'BMW 6 Series GT',
  'bmw+x6': 'BMW X6',
  'bmw+m5': 'BMW M5',
  'bmw+m2': 'BMW M2',
  'bmw+m4': 'BMW M4',
  'bmw+7-series': 'BMW 7 Series',
  'ford+ecosport': 'Ford EcoSport',
  'ford+endeavour': 'Ford Endeavour',
  'ford+freestyle': 'Ford Freestyle',
  'ford+figo': 'Ford Figo',
  'ford+aspire': 'Ford Aspire',
  'ford+mustang': 'Ford Mustang',
  'ford+z4': 'Ford Z4',
  'ford+x3': 'Ford X3',
  'ford+x4': 'Ford X4',
  'ford+6-series-gt': 'Ford 6 Series GT',
  'ford+x6': 'Ford X6',
  'ford+m5': 'Ford M5',
  'ford+m2': 'Ford M2',
  'ford+m4': 'Ford M4',
  'ford+7-series': 'Ford 7 Series',
  'mercedes-benz+cla': 'Mercedes-Benz CLA',
  'mercedes-benz+c-class': 'Mercedes-Benz C-Class',
  'mercedes-benz+e-class': 'Mercedes-Benz E-Class',
  'mercedes-benz+gla': 'Mercedes-Benz GLA',
  'mercedes-benz+gle': 'Mercedes-Benz GLE',
  'mercedes-benz+gls': 'Mercedes-Benz GLS',
  'mercedes-benz+s-class': 'Mercedes-Benz S-Class',
  'mercedes-benz+glc': 'Mercedes-Benz GLC',
  'mercedes-benz+g-class': 'Mercedes-Benz G-Class',
  'mercedes-benz+cls': 'Mercedes-Benz CLS',
  'mercedes-benz+amg-gt': 'Mercedes-Benz AMG GT',
  'mercedes-benz+c-coupe': 'Mercedes-Benz C-Coupe',
  'mercedes-benz+glc-coupe': 'Mercedes-Benz GLC Coupe',
  'mercedes-benz+-c-class-cabriolet': 'Mercedes-Benz  C-Class Cabriolet',
  'mercedes-benz+-v-class': 'Mercedes-Benz  V-Class',
  'mercedes-benz+s-coupe': 'Mercedes-Benz S-Coupe',
  'mercedes-benz+gle-coupe': 'Mercedes-Benz GLE Coupe',
  'mercedes-benz+s-class-cabriolet': 'Mercedes-Benz S-Class Cabriolet',
  'mercedes-benz+-e-class-all-terrain': 'Mercedes-Benz  E-Class All Terrain',
  'kia+seltos': 'Kia Seltos',
  'mg+seltos': 'MG Seltos',
  'renault+kwid': 'Renault Kwid',
  'renault+duster': 'Renault Duster',
  'renault+captur': 'Renault Captur',
  'renault+lodgy': 'Renault Lodgy',
  'audi+q3': 'Audi Q3',
  'audi+a4': 'Audi A4',
  'audi+a3': 'Audi A3',
  'audi+q7': 'Audi Q7',
  'audi+a6': 'Audi A6',
  'audi+q5': 'Audi Q5',
  'audi+r8': 'Audi R8',
  'audi+a5': 'Audi A5',
  'audi+rs5': 'Audi RS5',
  'audi+a3-cabriolet': 'Audi A3 Cabriolet',
  'audi+rs7-sportback': 'Audi RS7 Sportback',
  'audi+a5-cabriolet': 'Audi A5 Cabriolet',
  'nissan+kicks': 'Nissan Kicks',
  'nissan+terrano': 'Nissan Terrano',
  'nissan+sunny': 'Nissan Sunny',
  'nissan+micra': 'Nissan Micra',
  'nissan+micra-active': 'Nissan Micra Active',
  'nissan+gt-r': 'Nissan GT-R',
  'volkswagen+polo': 'Volkswagen Polo',
  'volkswagen+vento': 'Volkswagen Vento',
  'volkswagen+ameo': 'Volkswagen Ameo',
  'volkswagen+tiguan': 'Volkswagen Tiguan',
  'volkswagen+passat': 'Volkswagen Passat',
  'volkswagen+gti': 'Volkswagen GTI',
  'skoda+rapid': 'Skoda Rapid',
  'skoda+octavia': 'Skoda Octavia',
  'skoda+kodiaq': 'Skoda Kodiaq',
  'skoda+superb': 'Skoda Superb',
  'isuzu+d-max-v-cross': 'Isuzu D-MAX V-Cross',
  'isuzu+mu-x': 'Isuzu MU-X',
  'datsun+redi-go': 'Datsun redi-GO',
  'datsun+go': 'Datsun GO',
  'datsun+go+': 'Datsun GO+',
  'datsun+': 'Datsun ',
  'mini+cooper': 'Mini Cooper',
  'mini+countryman': 'Mini Countryman',
  'mini+cooper-convertible': 'Mini Cooper Convertible',
  'mini+clubman': 'Mini Clubman',
  'force-motors+gurkha': 'Force Motors Gurkha',
  'aston-martin+v8-vantage': 'Aston Martin V8 Vantage',
  'aston-martin+db11': 'Aston Martin DB11',
  'aston-martin+rapide': 'Aston Martin Rapide',
  'jaguar+xj-l': 'Jaguar XJ L',
  'jaguar+xe': 'Jaguar XE',
  'jaguar+xf': 'Jaguar XF',
  'jaguar+f-pace': 'Jaguar F-Pace',
  'jaguar+f-type': 'Jaguar F-Type',
  'jeep+compass': 'Jeep Compass',
  'jeep+wrangler': 'Jeep Wrangler',
  'jeep+grand-cherokee': 'Jeep Grand Cherokee',
  'volvo+xc90': 'Volvo XC90',
  'volvo+xc40': 'Volvo XC40',
  'volvo+xc60': 'Volvo XC60',
  'volvo+s90': 'Volvo S90',
  'volvo+v40': 'Volvo V40',
  'volvo+s60': 'Volvo S60',
  'volvo+v90-cross-country': 'Volvo V90 Cross Country',
  'volvo+v40-cross-country': 'Volvo V40 Cross Country',
  'lexus+es': 'Lexus ES',
  'lexus+nx': 'Lexus NX',
  'lexus+lx': 'Lexus LX',
  'lexus+ls': 'Lexus LS',
  'lexus+rx': 'Lexus RX',
  'land-rover+range-rover-evoque': 'Land Rover Range Rover Evoque',
  'land-rover+discovery-sport': 'Land Rover Discovery Sport',
  'land-rover+range-rover': 'Land Rover Range Rover',
  'land-rover+range-rover-velar': 'Land Rover Range Rover Velar',
  'land-rover+range-rover-sport': 'Land Rover Range Rover Sport',
  'land-rover+discovery': 'Land Rover Discovery',
  'bentley+continental-gt': 'Bentley Continental GT',
  'bentley+mulsanne': 'Bentley Mulsanne',
  'bentley+bentayga': 'Bentley Bentayga',
  'bentley+continental-flying-spur': 'Bentley Continental Flying Spur',
  'porsche+macan': 'Porsche Macan',
  'porsche+cayenne': 'Porsche Cayenne',
  'porsche+718': 'Porsche 718',
  'porsche+911': 'Porsche 911',
  'porsche+panamera': 'Porsche Panamera',
  'mitsubishi+pajero-sport': 'Mitsubishi Pajero Sport',
  'mitsubishi+outlander': 'Mitsubishi Outlander',
  'mitsubishi+montero': 'Mitsubishi Montero',
  'fiat+punto-evo': 'Fiat Punto Evo',
  'fiat+fiat-linea': 'Fiat Fiat Linea',
  'fiat+abarth-punto': 'Fiat Abarth Punto',
  'fiat+avventura': 'Fiat Avventura',
  'fiat+urban-cross': 'Fiat Urban Cross',
  'fiat+linea-classic': 'Fiat Linea Classic',
  'rolls-royce+phantom-viii': 'Rolls-Royce Phantom VIII',
  'rolls-royce+cullinan': 'Rolls-Royce Cullinan',
  'rolls-royce+ghost-series-ii': 'Rolls-Royce Ghost Series II',
  'rolls-royce+wraith': 'Rolls-Royce Wraith',
  'rolls-royce+dawn': 'Rolls-Royce Dawn',
  'rolls-royce+phantom-coupe': 'Rolls-Royce Phantom Coupe',
  'rolls-royce+drophead-coupe': 'Rolls-Royce Drophead Coupe',
  'ferrari+488': 'Ferrari 488',
  'ferrari+812': 'Ferrari 812',
  'ferrari+california': 'Ferrari California',
  'ferrari+portofino': 'Ferrari Portofino',
  'ferrari+gtc4-lusso': 'Ferrari GTC4 Lusso',
  'maserati+quattroporte': 'Maserati Quattroporte',
  'maserati+levante': 'Maserati Levante',
  'maserati+ghibli': 'Maserati Ghibli',
  'maserati+granturismo': 'Maserati GranTurismo',
  'maserati+grancabrio': 'Maserati GranCabrio',
  'lamborghini+huracan': 'Lamborghini Huracan',
  'lamborghini+aventador': 'Lamborghini Aventador',
  'lamborghini+urus': 'Lamborghini Urus',
  'bugatti+veyron': 'Bugatti Veyron',
  'dc+avanti': 'DC Avanti',
};

export default carNames;
