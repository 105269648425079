export const LANDING = '/';
export const LISTINGS = '/cars';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/login';
export const CHECKOUT = '/checkout';
export const PRODUCTS = '/products/:id';
export const EARN = '/earn';
export const FAQ = '/faq';
export const JOIN = '/join';
export const HOW_IT_WORKS = '/how-it-works';
export const INSPECT = '/inspection/:id';
export const SUPPLIER = '/partner/:id';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const LEADS = '/deals';
export const SCHEDULE = '/pay-schedule';
export const PRIVACY = '/privacy';
export const CONTACT = '/contact';
export const TERMS = '/terms';
