import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProductListCard from '../ProductListCard';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 4),
    minHeight: '100vh',
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
  },
  product: {
    paddingRight: theme.spacing(1),
  },
}));

function Album(props) {
  const classes = useStyles();
  const {
    data = null, navigateToDetails, offerType = 'subscribe',
  } = props;

  const handleOpen = (item) => (event) => {
    // setOverlayData(item);
    navigateToDetails(event, item);
    // setOpen(true);
  };

  // const clearModel = (item) => {
  //   removeCarModel(id, item);
  //   updateMakeModel(id, item, false);
  // };

  const layoutView = () => {
    if (data.length > 0) {
      return (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="baseline"
          className={classes.cardGrid}
          spacing={4}
        >
          {data.map((car) => car.make !== '' && (
            <Grid item key={car.id} xs={12} sm={6} md={5} lg={4} onClick={handleOpen(car)}>
              <ProductListCard data={car} navigateToDetails={navigateToDetails} offerType={offerType} />
            </Grid>
          ))}
        </Grid>
      );
    }
    // if (!isObjEmpty(data[item])) {
    //   return (
    //     <div key={item} className={classes.cardGrid}>
    //       <Grid
    //         container
    //         direction="row"
    //         justify="space-between"
    //         alignItems="baseline"
    //       >
    //         <Typography gutterBottom variant="h6">
    //           {getCarName(item)}
    //         </Typography>
    //         <Button onClick={() => clearModel(item)}>
    //       Clear
    //         </Button>
    //       </Grid>
    //       <Carousel viewItems={[4, 1, 2]} partialVisible="right" slidesToSlide={2}>
    //         {data[item].map((car) => (
    //           <div role="presentation" key={car.id} className={classes.product} onClick={() => handleOpen(car)}>
    //             <ProductListCard data={car} navigateToDetails={navigateToDetails} />
    //           </div>
    //         ))}
    //       </Carousel>
    //     </div>
    //   );
    // }
    return null;
  };
  if (data === null) {
    return (
      <div className={classes.root}>
        <Loading />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      { data.length > 0 ? (
        <>
          {layoutView()}
        </>
      ) : (
        <Typography className={classes.cardGrid} variant="h6" align="center" color="textPrimary">
          No cars currently matches the parameters!
        </Typography>
      )}
    </div>
  );
}


Album.propTypes = {
  data: PropTypes.array,
  offerType: PropTypes.string,
  navigateToDetails: PropTypes.func,
};

export default Album;
