import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withAuthorization } from '../../common/Session';
import Image from '../../common/Image';


const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
  },
  layout: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 15),
    },
  },
}));

function KycForm(props) {
  const { onSubmit, user, data } = props;
  const classes = useStyles();

  const {
    register, handleSubmit, errors, setValue,
  } = useForm();

  const {
    kyc: {
      pan = '', panPhoto = '', aadhaar = '', aadhaarPhoto = '', dlPhoto = '',
    } = {},
  } = data;

  useEffect(() => {
    setValue('pan', pan);
    setValue('panPhoto', panPhoto);
    setValue('aadhaar', aadhaar);
    setValue('aadhaarPhoto', aadhaarPhoto);
    setValue('dlPhoto', dlPhoto);
  }, [data]);

  const imgPath = (name) => `users/${user.uid}/${name}`;

  const formSubmit = (datum) => { onSubmit(datum); };

  return (
    <div className={classes.layout}>
      <Typography variant="h5" align="center" className={classes.section} gutterBottom>
        KYC Info
      </Typography>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="pan"
              name="pan"
              label="PAN Number"
              fullWidth
              autoComplete="pan"
              InputLabelProps={{
                shrink: true,
              }}
              helperText="Don't worry! Your data is secure with us"
              inputRef={register({ required: true, maxLength: 10 })}
            />
            <Typography variant="body2" color="error">{errors.pan && 'Please ensure correct PAN number is entered'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justify="center">
            <Image name="panPhoto" path={imgPath('panPhoto')} inputRef={register({ required: true })} url={panPhoto} />
            <Typography variant="body2" color="error">{errors.panPhoto && 'PAN photo is missing'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="aadhaar"
              name="aadhaar"
              label="Aadhaar Number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="aadhaar"
              helperText="Please enter 12 digit Aadhaar number"
              inputRef={register({ required: true, maxLength: 15 })}
            />
            <Typography variant="body2" color="error">{errors.aadhaar && 'Please ensure correct Aadhaar number is entered'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justify="center">
            <Image name="aadhaarPhoto" path={imgPath('aadhaarPhoto')} inputRef={register({ required: true })} url={aadhaarPhoto} />
            <Typography variant="body2" color="error">{errors.aadhaarPhoto && 'Aadhar Photo missing'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Driving License Photo</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justify="center">
            <Image name="dlPhoto" path={imgPath('dlPhoto')} inputRef={register({ required: true })} url={dlPhoto} />
            <Typography variant="body2" color="error">{errors.dlPhoto && 'Driving License photo is missing'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid item container xs={12} justify="flex-end">
          <Button variant="contained" size="large" className={classes.button} onClick={handleSubmit(onSubmit)} color="primary">
            Submit
          </Button>
        </Grid>
      </form>
    </div>
  );
}

KycForm.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.object,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(KycForm);
