import { UPDATE_SUPPLIER_PROFILE, UPDATE_SUPPLIER_CARS } from '../../config/constants';

const initialState = {
  id: '',
  supplierProfile: {},
  supplierCars: null,
  lastVisible: null,
  noMoreCars: false,
};

export default function reducer(state = initialState, action) {
  const {
    type, id, data, lastVisible,
  } = action;

  switch (type) {
    case UPDATE_SUPPLIER_PROFILE:
      return {
        ...state,
        supplierProfile: data,
        id,
      };
    case UPDATE_SUPPLIER_CARS:
      return {
        ...state,
        supplierCars: data,
        lastVisible,
      };
    default:
      return state;
  }
}
