
// Utility functions
import { labels } from './translations';
import carNames from '../data/carNames';
import {
  ONE_MONTH_PERCENT,
  THREE_MONTH_PERCENT,
  SIX_MONTH_PERCENT,
  TWELVE_MONTH_PERCENT,
  ONE_MONTH_DIST,
  THREE_MONTH_DIST,
  SIX_MONTH_DIST,
  TWELVE_MONTH_DIST,
} from '../config/constants';

export const camelize = (str) => str && str.replace(str[0], str[0].toUpperCase());

export const getLabel = (str) => str && (labels[str] || camelize(str));

export const getCarName = (id) => id && (carNames[id] || '');

export const priceFormat = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getUniqId = () => Math.random().toString(36).substr(2, 9);

export const processUrl = (url, type) => {
  if (type === 'listing') {
    const params = url.pathname.split('/');
    const listObj = {};
    if (params && params[2]) {
      const car = params[2].split('+', 1);
      [listObj.make, listObj.model] = [car[0], params[2]];
    }
    if (params && params[3] && params[3] !== 'bengaluru') {
      const temp = params[3].split('-');
      listObj.price = [0, parseInt((temp[1] / 300), 10)];
    }
    if (url.search) {
      const urlParams = new URLSearchParams(url.search);
      listObj.type = urlParams.get('type', 'subscribe');
    }
    return listObj;
  } if (type === 'details') {
    const params = url.pathname.split('/');
    if (params && params[2]) {
      const carId = params[2].split('-').pop();
      return { carId };
    }
  }
  if (type === 'leads') {
    console.log(url);
    const { search } = url;
    if (search) {
      const urlParams = new URLSearchParams(search);
      const forwardTo = urlParams.get('forwardTo', null);
      return { forwardTo };
    }
    return { forwardTo: null };
  }
  return null;
};

export const getPriceQuery = (mainKey, slideValue) => {
  const query = [];
  const multiplier = 300;
  const [low, high] = slideValue;
  if (low !== 0) {
    query.push({
      key: 'low', field: mainKey, operator: '>=', filterValue: low * multiplier,
    });
  }
  if (high !== 100) {
    query.push({
      key: 'high', field: mainKey, operator: '<=', filterValue: high * multiplier,
    });
  }

  return query;
};

export const isObjEmpty = (obj) => obj && Object.entries(obj).length === 0 && obj.constructor === Object;

export const hyphenate = (str) => str && str.replace(/\s+/g, '-');

export const deleteProperty = (obj = {}, key) => {
  const { [key]: _, ...newObj } = obj;
  return newObj;
};

export const deleteArrayElement = (givenArr, deleteArr, key) => givenArr.filter((item) => !deleteArr.includes(item[key]));

export const getProductTitle = ({
  make, model, fuel,
}) => `${make} ${model} ${camelize(fuel)}`;

export const createProductUrl = ({
  id, makeModel, makeYear,
}) => `${makeModel}-${makeYear}-${id}`;

export const getMonthRate = (month) => {
  switch (month) {
    case 3: return THREE_MONTH_PERCENT;
    case 6: return SIX_MONTH_PERCENT;
    case 1: return ONE_MONTH_PERCENT;
    default: return TWELVE_MONTH_PERCENT;
  }
};

export const getMinDist = (month) => {
  if (month === 1) return ONE_MONTH_DIST;
  if (month === 3) return THREE_MONTH_DIST;
  if (month === 6) return SIX_MONTH_DIST;
  return TWELVE_MONTH_DIST;
};

export const oneTimeFormula = (totalPrice, ind, priceList) => {
  const tempOtp = priceList[2] ? priceList[2] * 5 : totalPrice * THREE_MONTH_PERCENT;
  console.log(tempOtp);
  // return ((tempOtp * (ind + 1)) / 5);
  return 10000 * (ind + 1);
};
