import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import LoginPage from '../../containers/LoginPage';
import { logout } from '../../server/auth';
import makeLogo from '../../images/logo.png';
import brandLogo from '../../images/brand.jpg';
import AuthUserContext from '../../common/Session/context';
import {
  LISTINGS, ACCOUNT, EARN, FAQ, JOIN,
} from '../../config/routes';
import { analytics } from '../../server/config';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  title: {
    padding: theme.spacing(2, 4),
    width: '100%',
  },
  logoCont: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  logo: {
    width: 'auto',
    height: 50,
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },
  menuButton: {
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  otherLinks: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer',
  },
}));

function NavBar(props) {
  const {
    history, noBorder = false, hideLogin = false, pricingRef = null,
  } = props;
  const classes = useStyles();

  const [openMenu, setMenuOpen] = useState(false);
  const [login, setLogin] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAccClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = () => {
    setLogin(true);
    setMenuOpen(true);
  };

  const handleLogout = () => {
    logout();
    handleAccClose();
  };

  const goToProfile = () => {
    history.push(ACCOUNT);
    handleAccClose();
  };

  const navigateToJoin = () => {
    analytics.logEvent('select_promotion', { promotion_name: 'header' });
    history.push(`${JOIN}`);
  };

  const handleMenuClose = () => { setMenuOpen(false); };

  const handleMenuClick = () => { setLogin(false); setMenuOpen(true); };

  const menuDrawer = () => (
    <>
      <Button color="secondary" className={classes.otherLinks} onClick={() => history.push(`${LISTINGS}/all/bengaluru?type=sale`)}>Cars for Sale</Button>
      <Button color="secondary" className={classes.otherLinks} onClick={() => history.push(`${LISTINGS}/all/bengaluru`)}>Subscription</Button>
      {pricingRef && <Button color="secondary" className={classes.otherLinks} onClick={() => window.scrollTo({ top: pricingRef.current.offsetTop, behavior: 'smooth' })}>Pricing</Button>}
      <Button color="secondary" className={classes.otherLinks} onClick={() => history.push(FAQ)}>FAQ</Button>
      <Button color="secondary" className={classes.otherLinks} onClick={() => history.push(EARN)}>Earn With Us</Button>
    </>
  );

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <>
          <AppBar position="sticky" className={classes.appBar} elevation={0}>
            <Toolbar>
              <Hidden smUp>
                <IconButton edge="start" onClick={() => handleMenuClick()} className={classes.menuButton} color="inherit" aria-label="menu">
                  <MenuIcon color="primary" />
                </IconButton>
              </Hidden>
              <Typography variant="h6" color="inherit" className={classes.logoCont} onClick={() => history.push('/')}>
                <img src={noBorder ? makeLogo : brandLogo} className={classes.logo} alt="logo" />
              </Typography>
              <Hidden xsDown>
                <Typography variant="body2" color="secondary" className={classes.grow}>
                  Bengaluru
                </Typography>
                {menuDrawer()}
              </Hidden>
              {authUser ? (
                <Tooltip title="Account" aria-label="Account">
                  <Avatar alt="Naval Tharun" className={classes.avatar} onClick={handleAccClick}>N</Avatar>
                </Tooltip>
              )
                : (!hideLogin && (
                  <>
                    <Button className={classes.otherLinks} color="secondary" size="large" onClick={() => handleSignIn()}>Log In</Button>
                    <Hidden xsDown>
                      <Button variant="contained" color="primary" disableElevation onClick={() => navigateToJoin()}>Join Now</Button>
                    </Hidden>
                  </>
                ))}
            </Toolbar>
            {noBorder ? null : <Divider /> }
          </AppBar>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleAccClose}
          >
            <MenuItem onClick={() => goToProfile()}>Account</MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          </Menu>
          <Dialog
            fullScreen={fullScreen}
            open={openMenu}
            onClose={handleMenuClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="lg"
          >
            <DialogTitle id="responsive-dialog-title" disableTypography className={classes.title}>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleMenuClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={5}
              >
                {login ? <LoginPage onCancel={handleMenuClose} /> : menuDrawer()}
              </Grid>
            </DialogContent>
            { !login && (
              <DialogActions>
                <Button onClick={handleMenuClose} color="primary">
                  Close
                </Button>
                <Button onClick={handleMenuClose} color="primary" autoFocus>
                  Done
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </>
      )}
    </AuthUserContext.Consumer>
  );
}

NavBar.propTypes = {
  history: PropTypes.object,
  pricingRef: PropTypes.object,
  noBorder: PropTypes.bool,
};

export default withRouter(NavBar);
