export const labels = {
  // report
  // exterior
  frontBumper: 'Front Bumper',
  rearBumper: 'Rear Bumper',
  spareTyre: 'Spare Tyre',
  bonnet: 'Bonnet',
  fenderFR: 'Front right fender',
  fenderFL: 'Front left fender',
  turnIndicFR: 'Turn signal indicator - Front right',
  turnIndicRR: 'Turn signal indicator - Rear right',
  turnIndicRL: 'Turn signal indicator - Read left',
  turnIndicFL: 'Turn signal indicator - Front left',
  pillarAFR: 'Front right pillar A',
  pillarALF: 'Left front pillar A',
  pillarBRC: 'Right Center Pillar B',
  pillarBLC: 'Left Center Pillar B',
  pillarCRR: 'Rear right pillar C',
  pillarCLR: 'Left Rear Pillar C',
  rhRunBoard: 'Right running board',
  ltRunBoard: 'Left Running Board',
  doorFR: 'Front Right Door',
  doorRR: 'Rear Right Door',
  doorRL: 'Rear Left Door',
  doorLF: 'Left Front Door',
  bootDoor: 'Boot/Dicky door',
  bootFloor: 'Dicky floor',
  bootWall: 'Dicky Wall',
  rhQrtPanel: 'Right quarter panel',
  ltQrtPanel: 'Left Quarter Panel',
  tyreTrimFR: 'Front right tyre trim',
  tyreTrimRR: 'Rear right tyre trim',
  tyreTrimRL: 'Rear left Tyre Trim',
  tyreTrimFL: 'Front Left Tyre Trim',
  rhTailLight: 'Right tail light',
  ltTailLight: 'Left tail light',
  rhHeadLight: 'Right Headlight',
  ltHeadLight: 'Left Headlight',
  dentsFree: 'Dents free',
  scratchFree: 'Scratch free',
  corroFree: 'Corrosion free',
  notRepaint: 'Not repainted',
  notRepair: 'Not repaired',
  notReplace: 'Not replaced',
  crackFree: 'Crack free',
  paintNoNeed: 'Paint not required',
  notFaded: 'Not faded',
  bulbWork: 'Bulb working',
  noBreak: 'No breakage',
  notDamage: 'Not damaged',
  noJackRepair: 'No Jack Repair',
  corroMin: 'Minor Corrosion',
  corroMaj: 'Major Corrosion',
  punchOpen: 'Punches Open',
  punchRepaired: 'Punches repaired',
  // Engine
  rhApron: 'Right Apron',
  rhHeadLightSup: 'Right Headlight support',
  rhSideMemb: 'Right Side Member',
  rhSideWall: 'Right Side walls',
  ltApron: 'Left Apron',
  ltHeadLightSup: 'Left headlight support',
  ltSideMemb: 'Left side Member',
  ltSideWall: 'Left side walls',
  cowlTop: 'Cowl Top',
  crossMemb: 'Cross Member',
  engMount: 'Engine Mount',
  radiatSup: 'Radiator Support ',
  coldStartEng: 'Cold start',
  startMotor: 'Starter Motor',
  ignCoil: 'Ignition coils',
  battery: 'Battery',
  engComp: 'Engine Compartment',
  noiseEngComp: 'Noise Check - Engine compartment',
  noiseAC: 'Noise check: AC compressor',
  alt: 'Alternator',
  wiper: 'Wipers',
  acComp: 'AC compressor - Engine compartment',
  coolingSys: 'Cooling system functionality',
  coolantLvl: 'Coolant level',
  acHose: 'AC hose',
  cngPipe: 'CNG pipe',
  coolantHose: 'Coolant hose',
  masterCyl: 'Master cylinder',
  powSteerGear: 'Power Steering Gearbox',
  rackPinion: 'Rack & Pinion',
  noIdleVib: 'No idle vibration present',
  noWhiteSmoke: 'No white smoke',
  noBlackSmoke: 'No black smoke',
  noEngMisfire: 'No engine misfire',
  engStartCrank: 'Engine starts in single crank',
  battGood: 'Battery in good condition',
  noCutDrvBelt: 'No cuts & cracks on drive belt',
  noSludgeLeak: 'No sludge and other fluid leaks',
  engElecGood: 'Engine electricals in good condition',
  oilFiltCap: 'Blow by dipstick/oil filter cap',
  tappetNotOpen: 'Tappet Cover not open',
  timeCovNotOpen: 'timing cover not open',
  tappetNoiseAbs: 'Tappet noise absent',
  altNoiseAbs: 'Alternator noise absent',
  bigNoiseAbs: 'Big and bearing noise absent',
  tensNoiseAbs: "Tensioner/pulley's noise absent",
  waterNoiseAbs: 'water pump noise absent',
  alNoDamage: 'Alternator belt has no cracks/damages',
  noLinkNoise: 'No linkage noise',
  wiperMotWorks: 'Wiper motor working',
  wiperArmNotBroken: 'Wiper arm is not broken',
  wiperBladeWorks: 'Wiper blade working',
  radiatGood: 'Radiator in good condition',
  radiatFanWorks: 'Radiator fan operating properly',
  reservNoLeak: 'Reservoir has no leakage',
  noOilFloats: 'No oil or foreign material floating',
  coolantLeak: 'Coolant leakage',
  radiatNoDamage: 'Radiator is not damaged',
  radiatHose: 'Radiator hose pipe',
  coolantHoseGood: 'Coolant hose in good condition',
  masterCylNoLeak: 'No leakage in master cylinder/booster',
  rackPinionGood: 'Rack Pinion in good condition',
  noFluidLeak: 'No fluid leakage',
  // underbody
  mainOilSeal: 'Main Oil Seal - underbody',
  diff: 'Differential',
  brakeSys: 'Brake System - underbody',
  suspens: 'Suspension - underbody',
  univJoint: 'Universal Joint - underbody',
  catalConv: 'Catalytic convertor',
  muff: 'Muffler',
  tailPipe: 'Tail pipe',
  leakFree: 'Leakage free',
  wheelCylGood: 'Wheel cylinder is in good condition',
  brakeDiskGood: 'Brake Disk/Pads are in good condition',
  ibjCrackFree: 'IBJ is crack free',
  // Optional
  turboLvl: 'Turbo Oil Level',
  fourWD: '4 wheel drive',
  // interiors
  frontWindShield: 'Front wind shield',
  rhQrtGlass: 'Quarter Glass Right',
  ltQrtGlass: 'Quarter Glass Left',
  rearWindShield: 'Rear wind sheild',
  doorGlassFR: 'Front right door Glass',
  doorGlassRR: 'Rear Right Door Glass',
  doorGlassRL: 'Rear left Door Glass',
  doorGlassFL: 'Front left door glass',
  lockSys: 'Locking System - Interior',
  ltSideMirror: 'Left Side Mirror',
  rtSideMirror: 'Right Side Mirror',
  centRVMirror: 'Central Rear View Mirror',
  uphols: 'Upholstery',
  absLights: 'ABS lights',
  srsLights: 'SRS lights',
  clustPanel: 'Cluster Panel',
  dash: 'Dashboard',
  stereo: 'Audio/Stereo',
  horn: 'Horn',
  powerWind: 'Power windows',
  powerWindFR: 'Front right door power window',
  powerWindFL: 'Front left door power window',
  powerWindRR: 'Rear right door power window',
  powerWindRL: 'Rear left doow power window',
  seatbelt: 'Seat Belt ',
  acInt: 'AC - interiors',
  chipFree: 'Chipping free',
  spotFree: 'Spot free',
  assemblyGood: 'Assembly in good condition',
  driverSeatGood: 'Driver seat in good condition',
  coSeatGood: 'Co-passenger seat in good condition',
  rearSeatGood: 'Rear seat in good condition',
  roofLineGood: 'Roof lining in good condition',
  centConsGood: 'Central console in good condition',
  rearArmGood: 'Rear arm rest in good condition',
  doorTrimNotWorn: 'Door trims are not worn out',
  floorMatGood: 'Floor mats are free of tear',
  speedoGood: 'No problem in speedo meter',
  odoGood: 'No problem in odometer',
  tachoGood: 'No problem in tachometer',
  fuelGuageGood: 'No problem in fuel guage',
  tempMeterGood: 'no problem in temperature meter',
  srsLightsGood: 'no problem SRS lights',
  engLightGood: 'Cluster Panel > Engine Light',
  acFuncGood: 'AC function works',
  headAdjGood: 'Headlight Adjustor works',
  acVent: 'AC vent',
  hornGood: 'Horn operating as designed',
  seatbeltGood: 'Seat belts are working properly',
  acBlowerGood: 'AC blower works as designed',
  acCoolGood: 'AC cooling is effective',
  // road Test
  powerSteer: 'Power steering',
  steerRT: 'Steering - Road Test',
  engineRT: 'Engine - Road Test',
  propShaftRT: 'Propeller shaft - Road test',
  transmRT: 'Transmission - Road test',
  clutchRT: 'Clutch - Road test',
  brakesRT: 'Brakes - Road test',
  absRT: 'ABS - Road test',
  suspRT: 'Suspension - Road test',
  rattlingRT: 'Rattling - Road test',
  fourWDRT: '4 Wheel Drive - Road test',
  meterTamperRT: 'Meter tampering',
  chassisRT: 'Chassis impression',
  tieRodNoiseAbs: 'Tie rod ends/lower arm has no abnormal noise',
  steerNoiseAbs: 'No abnormal steering noise',
  noArbSteer: 'No arbitrary steering play',
  alignGood: 'Alignment is good',
  engSmooth: 'engine runs smoothly',
  engPowerGood: 'engine power is as per requisite',
  noEngHunt: 'no engine hunting',
  turbo: 'turbo',
  interCool: 'engine > inter cooler',
  brakeShoe: 'brake shoe',
  gearShiftGood: 'proper gear shifting engagement',
  gearShiftPlay: 'proper gear shift lever play',
  gearDiffNoiseAbs: 'no noise in gear box differential',
  pedalPlayGood: 'no problem in pedal play',
  clutchSlipAbs: 'no slippage between clutch & flywheel',
  clutchRespGood: 'clutch responds properly',
  noNoise: 'No noise',
  noDrift: 'No drifting',
  noVib: 'No vibration',
  handBrakeGood: 'No problem in handbrake operation',
  brakesEff: 'Brakes are efficient',
  bushNoiseAbs: 'bushes have no abnormal noise',
  wheelNoiseAbs: 'wheel bearing have no abnormal noise',
  shockersFR: 'front right shockers',
  shockersFL: 'front left shockers',
  shockersRR: 'rear right shockers',
  shockersRL: 'rear left shockers',
  linkRods: 'link rods',
  coilSpringRL: 'rear left coil spring',
  coilSpringRR: 'rear right coil spring',
  leafSpring: 'leaf springs',
  doorNoRatt: 'no rattling in doors',
  dashNoRatt: 'no rattling in dashboard',
  keyOdoMatch: 'key condition matching with reported odometer reading',
  tyreOdoMatch: 'tyre condition matching with reported odometer reading',
  pedalOdoMatch: 'pedal condition matching with reported odometer reading',
  drivOdoMatch: 'drivability condition matching with reported odometer reading',
  steerOdoMatch: 'steering wheel condition matching with reported odometer reading',
  powerWindOdoMatch: 'power window condition matching with reported odometer reading',
  seatOdoMatch: 'driver seat condition matching with reported odometer reading',
  combOdoMatch: 'combination switches condition matching with reported odometer reading',
  cooling: 'Cooling',
  abs: 'ABS',
  powerWindows: 'Power Windows',
  registrationNo: 'Registration No.',
  kms: 'Kilometers',
  transmission: 'Transmission',
  variant: 'Variant',
  manufacturingYear: 'Manufacturing Year',
  driveType: 'Drive Type', // 2wd or 4wd
  city: 'City',
  color: 'Color',
  bodyType: 'Body Type',
  fuel: 'Fuel',
  make: 'Make',
  makeModel: 'Model',
  model: 'Model',
  subscribePrice: 'Monthly Payment',
  price: 'Price',
  features: 'Features',
  under5000: 'Below Rs.5000',
  under10000: 'Below Rs.10000',
  under15000: 'Below Rs.15000',
  over15000: 'Above Rs.15000',
  total: 'Total',
  //  photos
  exterior: 'Exteriors',
  interior: 'Interiors',
  wheels: 'Wheels',
  suspensionBrakes: 'Suspension & Brakes',
  systems: 'Systems & Functions',
  underbody: 'Underbody',
  frontView: 'Front View',
  frontLSAV: 'Front Left Side Angle View',
  LSView: 'Left Side View',
  rearLSAV: 'Rear Left Side Angle View',
  rearView: 'Rear View',
  rearRSAV: 'Rear Right Side Angle View',
  RSView: 'Right Side View',
  frontRSAV: 'Front Right Side Angle View',
  frontWT: 'Front Windshield & Top',
  dashb: 'Dashboard',
  musicS: 'Music System',
  odo: 'Odometer Reading',
  backWT: 'Back Windshield & Top',
  backSeatF: 'Back seats view from front',
  frontSV: 'Front row from side',
  backSV: 'Back row from side',
  frontRDP: 'Front Right Door Panel',
  rearRDP: 'Rear Right Door Panel',
  rearLDP: 'Rear Left Door Panel',
  frontLDP: 'Front Left Door Panel',
  trunkOpen: 'Trunk door open view',
  trunkInt: 'Trunk Interior Close up view',
  trunkFloor: 'Trunk Floor with spare tyre',
  hoodOpen: 'Hood open view',
  engineTop: 'Engine top view',
  frontLR: 'Front Left Rim',
  rearLR: 'Rear Left Rim',
  rearRR: 'Rear Right Rim',
  frontRR: 'Front Right Rim',
  // details
  freeReturn: '1 DAY RISK FREE RETURN',
  periodic: 'PERIODIC MAINTENANCE',
  road: 'ROAD SIDE ASSISTANCE',
  addons: 'AVAILABLE ADD-ONS',
  insure: 'INSURANCE',
  addon: 'AVAILABLE ADD-ONS',
  // account
  aadhaarPhoto: 'Aadhaar Photo',
  panPhoto: 'PAN Photo',
  chequePhoto: 'Cheque Photo',
  dlPhoto: 'DL photo',
  // Features
  smartKey: 'Smart Key',
  infotSys: 'Infotainment System',
  navigSys: 'Navigation System',
  alloyWheel: 'Alloy Wheel',
  aux: 'AUX',
  usb: 'USB',
  ac: 'AC',
};
