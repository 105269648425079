import { database, storage } from './config';

export const getListData = (collectName, cb, lmt = 10) => {
  database.collection(collectName).limit(lmt).get()
    .then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const datum = doc.data();
        const { id } = doc;
        return { ...datum, id };
      });
      const source = querySnapshot.metadata.fromCache ? 'local cache' : 'server';
      console.log(`Listing data came from ${source}`);
      cb(data);
    })
    .catch((error) => {
      console.error('Error reading collection: ', error);
    });
};


export const getDocData = (collectName, docId) => new Promise(
  (resolve, reject) => {
    database.collection(collectName).doc(docId).get()
      .then((doc) => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          const datum = doc.data();
          const { id } = doc;
          resolve({ ...datum, id });
        }
      }).catch((error) => {
        reject(error);
      });
  }
);

export const setDocData = (collectName, docId, data) => new Promise(
  (resolve, reject) => {
    database.collection(collectName).doc(docId).set(data, { merge: true }).then(() => {
      resolve('Success');
    }).catch((error) => {
      reject(error);
    });
  }
);

export const getDocSubData = (collectName, docId, subId) => new Promise(
  (resolve, reject) => {
    database.collection(collectName).doc(docId).collection('protected').doc(subId).get()
      .then((doc) => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          const datum = doc.data();
          const { id } = doc;
          resolve({ ...datum, id });
        }
      }).catch((error) => {
        reject(error);
      });
  }
);

export const getFilteredData = (collectName, queries, lmt = 9, startDoc = null) => {
  const filterRef = database.collection(collectName);
  let listing = queries && queries.reduce((chain, { field, operator, filterValue }) => chain.where(field, operator, filterValue), filterRef);
  listing = listing.where('editStatus', '==', 'closed').where('listOnline', '==', true).orderBy('subscribePrice');
  if (startDoc !== null) {
    listing = listing.startAfter(startDoc);
  }
  return new Promise(
    (resolve, reject) => {
      listing.limit(lmt).get().then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          const datum = doc.data();
          const { id } = doc;
          return { ...datum, id };
        });
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        resolve([data, lastVisible]);
        const source = querySnapshot.metadata.fromCache ? 'local cache' : 'server';
        console.log(`Filtered data came from ${source}`);
      })
        .catch((error) => {
          reject(error);
        });
    }
  );
};

export const uploadImage = (path, file) => {
  const metadata = {
    contentType: 'image/jpeg',
  };
  return new Promise(
    (resolve, reject) => {
      storage.ref().child(path).put(file, metadata)
        .then((snapshot) => snapshot.ref.getDownloadURL()
          .then((downloadURL) => {
            resolve(downloadURL);
          })).catch((error) => {
          reject(error);
        });
    }
  );
};

export const addData = (collectName, docName, data) => new Promise(
  (resolve, reject) => {
    database.collection(collectName).doc(docName).set(data)
      .then((docRef) => {
        console.log(docRef);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }
);

// export const getData = () => {
//   database.collection('carProfiles').where('make', '==', 'Mahindra Thar').get()
//     .then((querySnapshot) => {
//       const data = querySnapshot.docs.map((doc) => doc.data());
//       console.log(data); // array of objects
//     })
//     .catch((error) => {
//       console.error('Error adding document: ', error);
//     });
// };
