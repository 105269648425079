import { UPDATE_CHECKOUT } from '../../config/constants';

const carInfo = {
  registrationNo: 'DLCYZZPWAX',
  chassisNo: '',
  engineNo: '',
  kms: '26000',
  manufacturingYear: '2019',
  driveType: '2wd',
  city: 'Chennai',
  color: 'Red',
  previousOwners: 2,
  mileage: '16.96',
  carModelId: 'carModelId',
  sellerId: '3erw3221e',
  make: 'Hyundai',
  model: 'Creta',
  makeModel: 'hyundai+creta',
  bodyType: 'Hatchback',
  variant: 'CRDE 4X4 Top End',
  fuel: 'Petrol',
  transmission: 'Manual',
  subscribePrice: 15000, // 3 year plan - per month payment price
  total: 2500,
  engine: '1500', // cc
  seats: 5,
};

const initialState = {
  carInfo,
  plan: null,
  subs: null,
  mileage: '',
  oneTime: '',
  month: '',
  amount: '',
};

export default function reducer(state = initialState, action) {
  const {
    type, key, value,
  } = action;

  switch (type) {
    case UPDATE_CHECKOUT:
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
}
