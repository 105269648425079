import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withAuthorization } from '../../common/Session';
import { isObjEmpty, priceFormat } from '../../helpers/utility';
import { getVehicleProfile, updateVehicleProfile } from './actions';
import Carousel from '../../components/Carousel';
import Image from '../../common/Image';
import { timestamp, arrayUnion } from '../../server/config';
import { uploadImage } from '../../server/database';


const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
  },
  layout: {
    minHeight: '70vh',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 15),
    },
  },
  lease: {
    margin: theme.spacing(4, 0),
  },
  table: {
    minWidth: 300,
  },
  userPhoto: {
    objectFit: 'cover',
  },
}));

function VehiclePage(props) {
  const { vehicleId } = props;
  const classes = useStyles();

  const [multiImgs, setMultiImgs] = useState([]);
  const [videoSrc, setVideo] = useState(null);
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);

  useEffect(() => {
    if (vehicleId && isObjEmpty(vehicle)) {
      dispatch(getVehicleProfile(vehicleId));
    }
  }, [vehicleId]);

  const setUserPhotos = (val) => setMultiImgs((oldArray) => [...oldArray, val]);

  const handleVideoChange = () => (event) => {
    const file = event.target.files[0];
    setVideo(file);
  };

  const handleSubmit = async () => {
    const data = {};
    if (videoSrc) {
      const videoUrl = await uploadImage(imgPath('vehicleVideo'), videoSrc);
      data.userVideo = videoUrl;
    } if (multiImgs.length > 0) {
      data.userPhotos = arrayUnion(multiImgs);
    }
    data.updatedAt = timestamp;
    dispatch(updateVehicleProfile(data, vehicleId));
    setVideo(null);
  };

  const imgPath = (name) => `vehicles/${vehicleId}/${name}`;

  const {
    userPhotos = [], profilePic = '', leaseAgreement = '', regnNo = '', userVideo = '', gstBool = false, subscribeAmt = 8000, deposit = 2000, months = 9,
  } = vehicle;

  const rows = useMemo(() => {
    const tempRows = [];

    tempRows.push({ id: 1, amt: `${priceFormat(Math.ceil(subscribeAmt))} + Rs ${priceFormat(deposit)}`, total: priceFormat(Math.ceil((subscribeAmt + deposit) * (gstBool ? 1.18 : 1))) });
    for (let i = 2; i <= months; i += 1) {
      const total = gstBool ? subscribeAmt * 1.18 : subscribeAmt;
      tempRows.push({ id: i, amt: priceFormat(Math.ceil(subscribeAmt)), total: priceFormat(Math.ceil(total)) });
    }
    return tempRows;
  }, [subscribeAmt, deposit, months]);

  return (
    <div className={classes.layout}>
      <Typography variant="h4" align="center" className={classes.section} gutterBottom>
        Vehicle Info
      </Typography>
      {vehicleId ? (
        <>
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              {profilePic && <img src={profilePic} className={classes.userPhoto} alt="profile" width="100%" height={300} />}
            </Grid>
            <Typography variant="h6" gutterBottom>{regnNo.toUpperCase()}</Typography>
            {leaseAgreement !== '' && (
              <Button className={classes.lease} variant="outlined" onClick={() => window.open(leaseAgreement, '_blank')}>
                Download Agreement
              </Button>
            )}
            <div className={classes.lease}>
              <Typography variant="h5" component="h4" align="center" gutterBottom>
                Payment Schedule
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                ( We&#39;ll be sending payment links through SMS & Email )
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      <TableCell align="right">Subscription Amount</TableCell>
                      <TableCell align="right">Total (with GST)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id === 1 ? (
                            <>
                              {row.id}
                              <Typography variant="caption" align="left" color="secondary">
                                {' (On day of receiving car)'}
                              </Typography>
                            </>
                          ) : row.id}
                        </TableCell>
                        <TableCell align="right">{`Rs ${row.amt}`}</TableCell>
                        <TableCell align="right">{`Rs ${row.total}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Divider style={{ width: '95%' }} />
            <br />
            <br />
          </Grid>
          <Grid container direction="row" justify="space-evenly" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>Photos Added by You</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {`Total ${userPhotos.length} photos (5 shown)`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Carousel showDots>
                {userPhotos.length > 0 ? userPhotos.slice(0, 5).map((photo) => (
                  <img key={photo} src={photo} className={classes.userPhoto} alt="user added" width="100%" height={300} />
                )) : (
                  <Typography variant="subtitle1" color="secondary" gutterBottom>
                    No photos added yet
                  </Typography>
                )}
              </Carousel>
            </Grid>
          </Grid>
          <br />
          <Grid container justify="flex-start">
            <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
              Add more pictures
            </Typography>
            <Image name="vehiclePics" path={imgPath('vehiclePics')} multiple setMultiImgs={setUserPhotos} />
          </Grid>
          <br />
          <Divider style={{ width: '95%' }} />
          <br />
          {userVideo !== '' ? <Typography variant="h6" align="center" color="primary" gutterBottom>Video of car already uploaded!</Typography>
            : <Typography variant="h6" align="center" color="error" gutterBottom>Pending video upload</Typography>}
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span">
              Upload Car Video
            </Button>
            <input
              style={{ display: 'none' }}
              id="raised-button-file"
              accept="video/*"
              capture="environment"
              type="file"
              onChange={handleVideoChange()}
            />
          </label>
          <br />
          <br />
          {videoSrc && <video width="320" src={URL.createObjectURL(videoSrc)} controls><track kind="captions" /></video>}
          <br />
          <Grid item container xs={12} justify="flex-end">
            <Button variant="contained" size="large" className={classes.button} onClick={() => handleSubmit()} color="primary">
              Submit Changes
            </Button>
          </Grid>
        </>
      ) : <Typography align="center" variant="body2" color="error">You have no vehicle subscribed yet!</Typography>}
    </div>
  );
}

VehiclePage.propTypes = {
  vehicleId: PropTypes.string,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(VehiclePage);
