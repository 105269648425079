import {
  UPDATE_PRODUCT_LIST_CARS, ADD_PRODUCT_LIST_FILTER, ADD_PRICE_FILTER,
  REMOVE_PRICE_FILTER, UPDATE_MODEL_LIST_FILTERS, RESET_PRODUCT_LIST_PAGE,
  ADD_PRODUCT_LIST_LASTDOC,
  REMOVE_PRODUCT_LIST_FILTER, REMOVE_PRODUCT_LIST_FILTERALL,
  REMOVE_PRODUCT_LIST_CARS, REMOVE_PRODUCT_LIST_CAR_MAKE,
  UPDATE_PRODUCT_LIST_MAKE_FILTER,
  UPDATE_PRODUCT_LIST_CARS_ALL,
  REMOVE_PRODUCT_LIST_CARS_ALL,
  APPEND_PRODUCT_LIST_CARS_ALL,
  NO_MORE_CARS,
} from '../../config/constants';
import { deleteProperty, deleteArrayElement } from '../../helpers/utility';

const initialState = {
  cars: {
    all: null,
  },
  filters: {
    subscribePrice: {
      low: 0,
      high: 100,
    },
    make: {
      'maruti-suzuki': false,
      hyundai: false,
      mahindra: false,
      renault: false,
      toyota: false,
      tata: false,
      honda: false,
      ford: false,
      fiat: false,
      kia: false,
      nissan: false,
      datsun: false,
    },
    fuel: {
      petrol: false,
      diesel: false,
      electric: false,
    },
    transmission: {
      manual: false,
      automatic: false,
    },
  },
  query: {
    field: 'makeKey', operator: 'in', filterValue: [],
  },
  subQueries: [],
  lastVisible: null,
  noMoreCars: false,
};

export default function reducer(state = initialState, action) {
  const {
    type, data, mainKey, subKey, toggle, query = {}, subQuery = [], slideValue = [0, 100], lastVisible,
  } = action;

  switch (type) {
    case ADD_PRODUCT_LIST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [mainKey]: {
            ...state.filters[mainKey],
            [subKey]: true,
          },
        },
        subQueries: [
          ...state.subQueries,
          query,
        ],
        noMoreCars: false,
      };
    case REMOVE_PRODUCT_LIST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [mainKey]: {
            ...state.filters[mainKey],
            [subKey]: false,
          },
        },
        subQueries: deleteArrayElement(state.subQueries, [subKey], 'key'),
        noMoreCars: false,
      };
    case REMOVE_PRODUCT_LIST_FILTERALL:
      return {
        ...state,
        filters: {
          ...state.filters,
          [mainKey]: initialState.filters[mainKey],
        },
        subQueries: deleteArrayElement(state.subQueries, [mainKey], 'field'),
        noMoreCars: false,
      };
    case ADD_PRICE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          subscribePrice: {
            low: slideValue[0],
            high: slideValue[1],
          },
        },
        noMoreCars: false,
        subQueries: [...deleteArrayElement(state.subQueries, ['low', 'high'], 'key'), ...subQuery],
      };
    case REMOVE_PRICE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          subscribePrice: deleteProperty(state.filters.subscribePrice, subKey),
        },
        noMoreCars: false,
        subQueries: deleteArrayElement(state.subQueries, [subKey], 'key'),
      };
    case UPDATE_MODEL_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          make: {
            ...state.filters.make,
            [mainKey]: {
              ...state.filters.make[mainKey],
              [subKey]: toggle,
            },
          },
        },
        noMoreCars: false,
      };
    case UPDATE_PRODUCT_LIST_CARS: // models
      return {
        ...state,
        cars: {
          ...state.cars,
          [mainKey]: {
            ...state.cars[mainKey],
            [subKey]: data,
          },
        },
        queries: {
          ...state.queries,
          [mainKey]: {
            ...state.queries[mainKey],
            [subKey]: query,
          },
        },
      };
    case REMOVE_PRODUCT_LIST_CARS: // models
      return {
        ...state,
        cars: {
          ...state.cars,
          [mainKey]: deleteProperty(state.cars[mainKey], subKey),
        },
        queries: {
          ...state.queries,
          [mainKey]: deleteProperty(state.queries[mainKey], subKey),
        },
      };
    case UPDATE_PRODUCT_LIST_MAKE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          make: {
            ...state.filters.make,
            [mainKey]: toggle,
          },
        },
        noMoreCars: false,
      };
    case UPDATE_PRODUCT_LIST_CARS_ALL: // all makes
      return {
        ...state,
        cars: {
          ...state.cars,
          all: data,
        },
        query,
      };
    case APPEND_PRODUCT_LIST_CARS_ALL: // all makes
      return {
        ...state,
        cars: {
          ...state.cars,
          all: [...state.cars.all, ...data],
        },
      };
    case REMOVE_PRODUCT_LIST_CARS_ALL: // all makes
      return {
        ...state,
        cars: deleteProperty(state.cars, 'all'),
      };
    case REMOVE_PRODUCT_LIST_CAR_MAKE: // make
      return {
        ...state,
        filters: {
          ...state.filters,
          make: {
            ...state.filters.make,
            [mainKey]: initialState.filters.make[mainKey],
          },
        },
        cars: deleteProperty(state.cars, mainKey),
        queries: deleteProperty(state.queries, mainKey),
      };
    case ADD_PRODUCT_LIST_LASTDOC:
      return {
        ...state,
        lastVisible,
      };
    case NO_MORE_CARS:
      return {
        ...state,
        noMoreCars: true,
      };
    case RESET_PRODUCT_LIST_PAGE:
      return initialState;
    default:
      return state;
  }
}
