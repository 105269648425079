export const UPDATE_HOME_CAR_LIST = 'UPDATE_HOME_CAR_LIST';

export const UPDATE_PRODUCT_LIST_CARS = 'UPDATE_PRODUCT_LIST_CARS';
export const ADD_PRODUCT_LIST_FILTER = 'ADD_PRODUCT_LIST_FILTER';
export const REMOVE_PRODUCT_LIST_FILTER = 'REMOVE_PRODUCT_LIST_FILTER';
export const REMOVE_PRODUCT_LIST_FILTERALL = 'REMOVE_PRODUCT_LIST_FILTERALL';
export const ADD_PRICE_FILTER = 'ADD_PRICE_FILTER';
export const REMOVE_PRICE_FILTER = 'REMOVE_PRICE_FILTER';
export const UPDATE_MODEL_LIST_FILTERS = 'UPDATE_MODEL_LIST_FILTERS';
export const REMOVE_PRODUCT_LIST_CARS = 'REMOVE_PRODUCT_LIST_CARS';
export const REMOVE_PRODUCT_LIST_CAR_MAKE = 'REMOVE_PRODUCT_LIST_CAR_MAKE';
export const RESET_PRODUCT_LIST_PAGE = 'RESET_PRODUCT_LIST_PAGE';
export const UPDATE_PRODUCT_LIST_CARS_ALL = 'UPDATE_PRODUCT_LIST_CARS_ALL';
export const REMOVE_PRODUCT_LIST_CARS_ALL = 'REMOVE_PRODUCT_LIST_CARS_ALL';
export const ADD_PRODUCT_LIST_LASTDOC = 'ADD_PRODUCT_LIST_LASTDOC';
export const APPEND_PRODUCT_LIST_CARS_ALL = 'APPEND_PRODUCT_LIST_CARS_ALL';
export const UPDATE_PRODUCT_LIST_MAKE_FILTER = 'UPDATE_PRODUCT_LIST_MAKE_FILTER';
export const NO_MORE_CARS = 'NO_MORE_CARS';

export const UPDATE_PRODUCT_DETAIL = 'UPDATE_PRODUCT_DETAIL';

export const UPDATE_INSPECT_REPORT = 'UPDATE_INSPECT_REPORT';

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';

export const UPDATE_SUPPLIER_PROFILE = 'UPDATE_SUPPLIER_PROFILE';
export const UPDATE_SUPPLIER_CARS = 'UPDATE_SUPPLIER_CARS';

export const HYDRATE_ACCOUNT = 'HYDRATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const HYDRATE_VEHICLE = 'HYDRATE_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const APPEND_VEHICLE = 'APPEND_VEHICLE';

export const ONE_MONTH_DIST = 1500;
export const THREE_MONTH_DIST = 4000;
export const SIX_MONTH_DIST = 9000;
export const TWELVE_MONTH_DIST = 20000;

export const THREE_MONTH_PERCENT = 0.15;
export const SIX_MONTH_PERCENT = 0.28;
export const TWELVE_MONTH_PERCENT = 0.45;
export const ONE_MONTH_PERCENT = 0.05;
