import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid/Grid';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { getCarName } from '../../helpers/utility';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '80vw',
    },
  },
  title: {
    padding: theme.spacing(2, 4),
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


function ExpandedFilterCard(props) {
  const {
    makeName, modelList, handleClose, removeCarModel, updateMakeModel, setMakeModelQuery, removeCarMake,
  } = props;
  const classes = useStyles();
  const [queryData, setQueryData] = useState([]);

  const handleChange = (subKey) => (event) => {
    if (event.target.checked) {
      if (subKey === makeName) {
        removeCarMake(makeName);
        setQueryData([{
          field: 'makeKey', operator: '==', filterValue: subKey,
        }]);
      } else {
        removeCarModel(makeName, makeName);
        updateMakeModel(makeName, makeName, false);
        setQueryData((chip) => [...chip, {
          field: 'makeModel', operator: '==', filterValue: subKey,
        }]);
      }
    } else {
      removeCarModel(makeName, subKey);
      setQueryData((chips) => chips.filter((chip) => chip.filterValue !== subKey));
    }
    updateMakeModel(makeName, subKey, event.target.checked);
  };

  const submitValues = () => {
    if (queryData.length > 0) {
      setMakeModelQuery(makeName, queryData);
    }
    handleClose();
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title" disableTypography className={classes.title}>
        <Typography variant="h6">Models</Typography>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => submitValues()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <FormControl component="fieldset">
          <FormGroup>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="baseline"
            >
              {Object.keys(modelList).map((item) => (
                <Grid item xs={12} md={4} key={item}>
                  <FormControlLabel
                    control={<Checkbox checked={modelList[item]} onChange={handleChange(item)} value={item} />}
                    label={<Typography className={classes.formControlLabel}>{item === makeName ? 'All' : getCarName(item)}</Typography>}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => submitValues()} color="primary">
          Done
        </Button>
      </DialogActions>
    </>
  );
}


ExpandedFilterCard.propTypes = {
  modelList: PropTypes.object,
  makeName: PropTypes.string,
  handleClose: PropTypes.func,
  setMakeModelQuery: PropTypes.func,
  updateMakeModel: PropTypes.func,
  removeCarMake: PropTypes.func,
  removeCarModel: PropTypes.func,
};


export default ExpandedFilterCard;
