import {
  UPDATE_PRODUCT_DETAIL,
} from '../../config/constants';
import { getDocData } from '../../server/database';

export function updateProductDetails(data) {
  return {
    type: UPDATE_PRODUCT_DETAIL, data,
  };
}

export function getCarProfile(carId) {
  console.log('detail call fired');
  return (dispatch) => {
    getDocData('carProfiles', carId).then(
      (data) => dispatch(updateProductDetails(data)),
      (error) => console.log(error)
    );
  };
}
