import { UPDATE_PRODUCT_DETAIL } from '../../config/constants';

const monthMarks = [
  {
    value: 0,
    label: '3 Mo',
  },
  {
    value: 25,
    label: '6 Mo',
  },
  {
    value: 50,
    label: '12 Mo',
  },
  {
    value: 75,
    label: '24 Mo',
  },
  {
    value: 100,
    label: '36 Mo',
  },
];
const oneTimeMarks = [
  {
    value: 0,
    label: 'Rs 10000',
  },
  {
    value: 25,
    label: 'Rs 20000',
  },
  {
    value: 50,
    label: 'Rs 30000',
  },
  {
    value: 75,
    label: 'Rs 40000',
  },
  {
    value: 100,
    label: 'Rs 50000',
  },
];
const mileageMarks = [
  {
    value: 0,
    label: '6000 Kms',
  },
  {
    value: 25,
    label: '12000 Kms',
  },
  {
    value: 50,
    label: '15000 Kms',
  },
  {
    value: 75,
    label: '20000 Kms',
  },
  {
    value: 100,
    label: '25000 Kms',
  },
];

const initialState = {
  data: {},
  monthMarks,
  oneTimeMarks,
  mileageMarks,
};

export default function reducer(state = initialState, action) {
  const {
    type, data,
  } = action;

  switch (type) {
    case UPDATE_PRODUCT_DETAIL:
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
}
