import { combineReducers } from 'redux';
import home from './containers/HomePage/reducer';
import productDetail from './containers/ProductDetailPage/reducer';
import productListing from './containers/ProductListingPage/reducer';
import checkout from './containers/Checkout/reducer';
import inspection from './containers/InspectionPage/reducer';
import account from './containers/AccountPage/reducer';
import vehicle from './containers/VehiclePage/reducer';
import supplier from './containers/SupplierPage/reducer';

export default combineReducers({
  home,
  productDetail,
  productListing,
  checkout,
  inspection,
  account,
  vehicle,
  supplier,
});
