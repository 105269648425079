import { UPDATE_HOME_CAR_LIST } from '../../config/constants';

export const initialState = {
  budgetLabels: ['< Rs. 15000', '< Rs. 21000', '< Rs. 24000', '> Rs. 24000'],
  carLabels: ['maruti-suzuki', 'honda', 'hyundai', 'tata'],
  cars: [],
};

// export const hiddenFieldList = ['photos', 'docId'];

export default function reducer(state = initialState, action) {
  const {
    type, data,
  } = action;

  switch (type) {
    case UPDATE_HOME_CAR_LIST:
      return {
        ...state,
        cars: data,
      };
    default:
      return state;
  }
}
