import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDropzone } from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { getLabel, getUniqId } from '../../helpers/utility';
import { uploadImage } from '../../server/database';

const useStyles = makeStyles((theme) => ({
  dropper: {
    width: 200,
    height: 150,
    border: '1px dotted #bbb',
    padding: theme.spacing(1),
    backgroundColor: '#f1f1f1',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

function Image(props) {
  const {
    name, path, url = '', inputRef, multiple = false, setMultiImgs,
  } = props;
  const classes = useStyles();
  const [downUrl, setDownUrl] = useState(url);
  const [multiUrl, setMultiUrl] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (multiple) {
      acceptedFiles.forEach(async (file) => {
        const returnUrl = await uploadImage(`${path}${getUniqId()}`, file);
        setMultiUrl((oldArray) => [...oldArray, returnUrl]);
        setMultiImgs(returnUrl);
      });
    } else {
      const returnUrl = await uploadImage(path, acceptedFiles[0]);
      setDownUrl(returnUrl);
    }
  }, []);

  const {
    getRootProps, getInputProps, isDragActive,
  } = useDropzone({
    onDrop,
    multiple,
    accept: ['image/*', 'application/pdf'],
  });

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <div
        {...getRootProps()}
        className={classes.dropper}
      >
        <input name={name} {...getInputProps()} />
        {
          isDragActive
            ? <p>{`Drop the ${getLabel(name)} here ...`}</p>
            : <p>{`Drag & drop ${getLabel(name)} here, or click to select`}</p>
        }
      </div>
      <input type="hidden" id={name} name={name} value={downUrl} ref={inputRef} />
      {downUrl !== '' && <img src={downUrl} width={200} height={150} alt={name} />}
      {multiUrl.map((item) => <img key={item} src={item} width={200} height={150} alt={name} />)}
    </Grid>
  );
}

Image.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Image);
