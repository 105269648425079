import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { setDocData, getDocData } from '../../server/database';
import { increment, timestamp } from '../../server/config';
import { processUrl } from '../../helpers/utility';
import Loading from '../../components/Loading';
import NavBar from '../../components/NavBar';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    [theme.breakpoints.up('md')]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  padder: {
    paddingTop: theme.spacing(5),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.background.default,
  },
}));

function LeadsPage(props) {
  const {
    type = 'customer', onCancel = () => {}, dialog = false, extraData = {}, location, history,
  } = props;

  const classes = useStyles();
  const {
    register, handleSubmit, errors, reset,
  } = useForm();
  const [leadRadio, setLeadRadio] = useState(type);
  const [openSnack, setOpenSnack] = useState(false);
  const [loader, setLoader] = useState(false);
  const [nextPath, setNextPath] = useState(null);

  useEffect(() => {
    if (location) {
      const { forwardTo } = processUrl(location, 'leads');
      setNextPath(forwardTo);
    }
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    const counter = await getDocData('leads', '--counter--');
    const docId = `${leadRadio === 'customer' ? 'cl' : 'sl'}0${counter.count}`;
    data.createdAt = timestamp;
    data.type = leadRadio;
    data.status = 'open';
    const setLeads = await setDocData('leads', docId, { ...data, ...extraData });
    if (setLeads === 'Success') {
      setOpenSnack(true);
      reset({});
      await setDocData('leads', '--counter--', { count: increment });
    } else {
      console.log(setLeads);
    }
    if (nextPath) {
      history.push(nextPath);
    }
    setLoader(false);
    onCancel();
  };

  const handleChange = (event) => {
    setLeadRadio(event.target.value);
  };

  if (loader) {
    return (
      <Loading />
    );
  }

  return (
    <>
      {!dialog && (
        <>
          <NavBar />
          <div className={classes.padder} />
        </>
      )}
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {!dialog && (
            <Typography component="h1" variant="h5" gutterBottom>
              Avail Deals!
            </Typography>
          )}
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.firstName}
                  autoComplete="name"
                  inputRef={register({ maxLength: 40 })}
                />
                <Typography variant="body2" color="error">{errors.firstName && 'Please ensure name is entered'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="primaryPhone"
                  name="primaryPhone"
                  label="Phone Number"
                  defaultValue="+91"
                  variant="outlined"
                  fullWidth
                  autoComplete="primaryPhone"
                  inputRef={register({ required: true, maxLength: 13, validate: (value) => value.length === 13 || value.length === 10 })}
                  helperText="Please enter a valid phone number"
                />
                <Typography variant="body2" color="error">{errors.primaryPhone && 'Please ensure valid phone number is entered'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  autoComplete="email"
                  inputRef={register({ pattern: /^\S+@\S+$/i })}
                />
                <Typography variant="body2" color="error">{errors.email && 'Your input should be in email format'}</Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <RadioGroup row aria-label="What's your aim" name="leadRadio" value={leadRadio} onChange={handleChange}>
                  <FormControlLabel value="supplier" control={<Radio />} label="Earn with your car" />
                  <FormControlLabel value="customer" control={<Radio />} label="Get a car" />
                </RadioGroup>
              </Grid>
              {leadRadio === 'supplier' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="regNo"
                    name="regNo"
                    label="Registration No."
                    variant="outlined"
                    fullWidth
                    autoComplete="regNo"
                    inputRef={register({ maxLength: 13 })}
                  />
                  <Typography variant="body2" color="error">{errors.firstName && 'Please ensure name is entered'}</Typography>
                </Grid>
              )}
            </Grid>
            <br />
            <Grid container justify="center">
              <Button variant="contained" onClick={handleSubmit(onSubmit)} color="primary">
                Contact Me!
              </Button>
            </Grid>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={() => setOpenSnack(false)}
        >
          <SnackbarContent
            message="We will call you back!"
          />
        </Snackbar>
      </main>
    </>
  );
}


LeadsPage.propTypes = {
  type: PropTypes.string,
  onCancel: PropTypes.func,
  dialog: PropTypes.bool,
  extraData: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default LeadsPage;
