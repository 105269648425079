import React, { Suspense, lazy } from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginPage from './containers/LoginPage';
import HomePage from './containers/HomePage';
import ProductListingPage from './containers/ProductListingPage';
import AccountPage from './containers/AccountPage';
import LeadsPage from './containers/LeadsPage';
import Loading from './components/Loading';
import * as ROUTES from './config/routes';
import { withAuthentication } from './common/Session';
import rootReducer from './store';

const Checkout = lazy(() => import('./containers/Checkout'));
const HowItWorksPage = lazy(() => import('./containers/HowItWorksPage'));
const EarnPage = lazy(() => import('./containers/EarnPage'));
const FaqPage = lazy(() => import('./containers/FaqPage'));
const ProductDetailPage = lazy(() => import('./containers/ProductDetailPage'));
const InspectionPage = lazy(() => import('./containers/InspectionPage'));
const JoinPage = lazy(() => import('./containers/JoinPage'));
const PaySchedulePage = lazy(() => import('./containers/PaySchedulePage'));
const PrivacyPage = lazy(() => import('./containers/PrivacyPage'));
const ContactPage = lazy(() => import('./containers/ContactPage'));
const TermsPage = lazy(() => import('./containers/TermsPage'));
const SupplierPage = lazy(() => import('./containers/SupplierPage'));

let theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#5ccb95', // 4fae61
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
    },
    secondary: {
      main: '#25354a',
      // dark: will be calculated from palette.secondary.main,
    },
    background: {
      paper: '#f8f8f8',
      default: '#fff',
    },
    // error: will use the default color
  },
  typography: {
    fontFamily: [
      'Arimo',
      'sans-serif',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);

const store = createStore(rootReducer, applyMiddleware(thunk));


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path={ROUTES.LANDING} component={HomePage} />
              <Route path={ROUTES.SIGN_IN} component={LoginPage} />
              <Route path={ROUTES.PRODUCTS} component={ProductDetailPage} />
              <Route exact path={ROUTES.CHECKOUT} component={Checkout} />
              <Route exact path={ROUTES.HOW_IT_WORKS} component={HowItWorksPage} />
              <Route path={ROUTES.LISTINGS} component={ProductListingPage} />
              <Route path={ROUTES.INSPECT} component={InspectionPage} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.EARN} component={EarnPage} />
              <Route path={ROUTES.FAQ} component={FaqPage} />
              <Route path={ROUTES.JOIN} component={JoinPage} />
              <Route path={ROUTES.LEADS} component={LeadsPage} />
              <Route path={ROUTES.SCHEDULE} component={PaySchedulePage} />
              <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
              <Route path={ROUTES.CONTACT} component={ContactPage} />
              <Route path={ROUTES.TERMS} component={TermsPage} />
              <Route path={ROUTES.SUPPLIER} component={SupplierPage} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default withAuthentication(App);
