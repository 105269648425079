import React, { useEffect, useState } from 'react';
import AuthUserContext from './context';
import { firebaseAuth } from '../../server/config';

const withAuthentication = (Component) => {
  function WithAuthentication(props) {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
      firebaseAuth().onAuthStateChanged((user) => {
        if (user) setAuthUser(user);
        else setAuthUser(null);
      });
      // write remove listener
    }, []);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return WithAuthentication;
};

export default withAuthentication;
