import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import Icon from '../Icon';
import { camelize, priceFormat, createProductUrl } from '../../helpers/utility';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    width: '100%',
    margin: theme.spacing(1),
    maxWidth: '90vw',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.default,
  },
  cardMedia: (props) => ({
    paddingTop: '75%', // 4:3
    width: '100%',
    opacity: props.soldOut ? 0.6 : 1,
  }),
  cardContent: {
    flexGrow: 1,
  },
  dash: {
    paddingTop: theme.spacing(2),
  },
  pills: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: theme.spacing(4),
    top: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
  },
}))(Badge);

function ProductListCard(props) {
  const { data = null, offerType = 'subscribe' } = props;
  const {
    photos, make, model, finalVerdict, dealerPhoto = '', mileage = '0', fuel, soldOut = false, transmission = 'Manual', pills = [], subscribePrice = 0,
  } = data;
  const classes = useStyles({ soldOut });

  if (!data) {
    return (
      <Card
        elevation={0}
        className={classes.card}
      >
        <CardContent>
          <Typography gutterBottom variant="h6" align="center" component="h3">
            + Click more
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      elevation={0}
      className={classes.card}
    >
      <StyledBadge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="secondary"
        badgeContent="Sold out"
        invisible={!soldOut}
      >
        <CardMedia
          className={classes.cardMedia}
          image={photos && photos.frontRSAV}
          title={`${make} ${model}`}
        />
      </StyledBadge>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="baseline"
        >
          <Link color="inherit" href={`/products/${createProductUrl(data)}`} style={{ textDecoration: 'none' }}>
            <Typography gutterBottom variant="subtitle1">
              {`${make} ${model}`}
            </Typography>
          </Link>
          <div>
            <Typography color="primary" align="right" variant="h6">
              &#x20b9;
              {' '}
              {priceFormat(offerType === 'sale' ? finalVerdict.insPredPrice : subscribePrice)}
              {offerType === 'subscribe' && (
                <Typography align="right" color="textSecondary" variant="caption">
                  /mo
                </Typography>
              )}
            </Typography>
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.dash}
        >
          <Typography variant="body2" align="center" color="textPrimary" gutterBottom>
            <Icon icon="fuel" viewBox="0 0 512 512" height={25} />
            <br />
            {camelize(fuel)}
          </Typography>
          <Typography variant="body2" align="center" color="textPrimary" gutterBottom>
            <Icon icon="gear" viewBox="0 0 600 600" height={25} />
            <br />
            {camelize(transmission)}
          </Typography>
          {dealerPhoto !== '' ? (
            <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
              Provided by
              <br />
              <img src={dealerPhoto} width="80" height="40" alt="dealer" />
            </Typography>
          ) : (
            <Typography variant="body2" align="center" color="textPrimary" gutterBottom>
              <Icon icon="mileage" viewBox="0 0 512 512" height={25} />
              <br />
              {`${mileage} Kms`}
            </Typography>
          )}
        </Grid>
        <>
          {pills.map((item) => <Chip key={item} variant="outlined" size="small" label={item} className={classes.pills} />) }
        </>
      </CardContent>
    </Card>
  );
}


ProductListCard.propTypes = {
  data: PropTypes.object,
  offerType: PropTypes.string,
};


export default ProductListCard;
