import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  layout: {
    minHeight: '70vh',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 15),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  form: {
    border: 'solid 1px #aaa',
    padding: theme.spacing(2, 4),
  },
}));

function Assistance(props) {
  const { assistCall } = props;
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState();
  const [leadRadio, setLeadRadio] = useState('general');
  const [openSnack, setOpenSnack] = useState(false);

  const {
    register, handleSubmit, errors, reset,
  } = useForm();

  const handleChange = (event) => {
    setLeadRadio(event.target.value);
  };

  const onSubmit = (data) => {
    data.type = leadRadio;
    assistCall(data);
    setFormOpen(false);
    setOpenSnack(true);
    reset({});
  };

  const maintainCall = (data = {}) => {
    data.type = 'service';
    assistCall(data);
    setOpenSnack(true);
  };

  return (
    <div className={classes.layout}>
      <Typography variant="h5" align="center" className={classes.section} gutterBottom>
        Assistance
      </Typography>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >

        <Button variant="outlined" href="tel:+916366336869" className={classes.button} color="primary" autoFocus>
          Call us
        </Button>
        <Button variant="outlined" className={classes.button} onClick={() => maintainCall()} color="primary">
          Raise Maintenance Request
        </Button>
        <Button variant="outlined" className={classes.button} onClick={() => setFormOpen(true)} color="primary">
          Report other issues
        </Button>
        {formOpen
          && (
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item container xs={12} justify="center">
                  <RadioGroup row aria-label="What's your aim" name="leadRadio" value={leadRadio} onChange={handleChange}>
                    <FormControlLabel value="payments" control={<Radio />} label="Related to payments" />
                    <FormControlLabel value="return" control={<Radio />} label="Return of car" />
                    <FormControlLabel value="general" control={<Radio />} label="General" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="details"
                    name="details"
                    label="Details"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    inputRef={register}
                  />
                  <Typography variant="body2" color="error">{errors.details && 'Please ensure name is entered'}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} container alignItems="flex-end">
                  <Button variant="contained" onClick={handleSubmit(onSubmit)} color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
      >
        <SnackbarContent
          message="Request raised!"
        />
      </Snackbar>
    </div>
  );
}

Assistance.propTypes = {
  assistCall: PropTypes.func,
};

export default Assistance;
