import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Slider(props) {
  const {
    children, viewItems = [], partialVisible = false, autoPlay = false, infinite = false, showDots = false, slidesToSlide = 1, mobileGutter = 10, removeArrowDevice = 'mobile',
  } = props;
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay={autoPlay}
      autoPlaySpeed={3000}
      centerMode={false}
      containerClass="container"
      draggable={false}
      focusOnSelect={false}
      infinite={infinite}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderDotsOutside={false}
      removeArrowOnDeviceType={removeArrowDevice}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: viewItems[0] || 1,
          paritialVisibilityGutter: 10,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: viewItems[1] || 1,
          paritialVisibilityGutter: mobileGutter,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: viewItems[2] || 1,
          paritialVisibilityGutter: 10,
        },
      }}
      showDots={showDots}
      sliderClass=""
      slidesToSlide={slidesToSlide}
      ssr
      swipeable
      partialVisible={partialVisible}
      dotListClass="custom-dot-list-style"
    >
      {children}
    </Carousel>
  );
}

Slider.propTypes = {
  children: PropTypes.node,
  partialVisible: PropTypes.string,
  removeArrowDevice: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  viewItems: PropTypes.array,
  autoPlay: PropTypes.bool,
  infinite: PropTypes.bool,
  showDots: PropTypes.bool,
  slidesToSlide: PropTypes.number,
  mobileGutter: PropTypes.number,
};

export default Slider;
