import { UPDATE_INSPECT_REPORT } from '../../config/constants';

const initialState = {
  inspectData: {
    exterior: {},
    engine: {},
    interior: {},
    underbody: {},
    roadTest: {},
    tyres: {},
    optional: {},
  },
};

export default function reducer(state = initialState, action) {
  const {
    type, data,
  } = action;

  switch (type) {
    case UPDATE_INSPECT_REPORT:
      return {
        ...state,
        inspectData: data,
      };
    default:
      return state;
  }
}
