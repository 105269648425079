import {
  UPDATE_VEHICLE, HYDRATE_VEHICLE,
} from '../../config/constants';
import { getDocData, setDocData } from '../../server/database';

export function hydrateVehicle(data) {
  console.log(data);
  return {
    type: HYDRATE_VEHICLE, data,
  };
}

export function updateVehicle(key, value) {
  return {
    type: UPDATE_VEHICLE, key, value,
  };
}

export function getVehicleProfile(id) {
  console.log('vehicle call fired');
  return (dispatch) => {
    getDocData('vehicles', id).then(
      (data) => dispatch(hydrateVehicle(data)),
      (error) => console.log(error)
    );
  };
}

export function updateVehicleProfile(data, id) {
  console.log('update vehicle fired');
  return (dispatch) => {
    setDocData('vehicles', id, data).then(
      (value) => value === 'Success' && dispatch(getVehicleProfile(id)),
      (error) => console.log(error)
    );
  };
}
