import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import {
  getMonthRate, getMinDist, oneTimeFormula, priceFormat,
} from '../../helpers/utility';

const useStyles = makeStyles((theme) => ({
  primText: {
    color: theme.palette.primary.main,
  },
  months: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  otpText: {
    paddingLeft: theme.spacing(1),
  },
  content: {
    textAlign: 'center',
    margin: theme.spacing(0, 6),
  },
  sliders: {
    padding: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'center',
  },
  slides: {
    width: '100%',
    padding: theme.spacing(2, 0),
  },
}));


const PriceSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    height: 8,
    paddingBottom: theme.spacing(3),
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  mark: {
    marginLeft: -3,
  },
  markLabel: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(7),
  },
  valueLabel: {
    left: 'calc(-50% + 40px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

function PaymentPage(props) {
  const {
    totalPrice = 560000, kmRate = 3, setSchedule = () => {}, priceMap = null, priceList = [], offerPrice = 0,
  } = props;

  const classes = useStyles();
  const [month, setMonth] = useState(12);
  const [oneTime, setOneTime] = useState(0);
  const [kms, setKms] = useState(0);
  const [amount, setAmount] = useState(7000);
  const [gst, setGst] = useState(false);

  // useEffect(() => {
  //   handleOneTimeSlider(0, 0);
  // }, []);
  //
  // useEffect(() => {
  //   setKms(0);
  // }, [month]);

  const getMonthPrice = (m) => {
    if (priceMap) {
      switch (m) {
        case 12: return priceMap['12mo'] * m;
        case 6: return priceMap['6mo'] * m;
        case 3: return priceMap['3mo'] * m;
        default: return priceMap['1mo'] * m;
      }
    } else if (priceList.length === 3) {
      switch (m) {
        case 12: return priceList[2] * m;
        case 6: return priceList[1] * m;
        default: return priceList[0] * m;
      }
    } else {
      return getMonthRate(m) * totalPrice;
    }
  };

  useEffect(() => {
    const extraDist = kms * 100;
    const tempCost = getMonthPrice(month);
    let price = (tempCost - oneTime + (extraDist * kmRate)) / month;
    console.log(oneTime);
    if (gst) {
      price *= 1.18;
    }
    setAmount(Math.ceil(price));
    const ownerPriceStr = priceList.length === 3 ? `&p0=${priceList[0]}&p1=${priceList[1]}&p2=${priceList[2]}` : '  ';
    setSchedule(`otp=${oneTime}&extra=${extraDist * kmRate}&pr=${totalPrice}${ownerPriceStr}`);
  }, [month, oneTime, kms, gst]);

  const handleOneTimeSlider = (evt, value) => {
    const onetp = createOneTimeMarks.filter((mark) => mark.value === value)[0].label;
    const [otp] = onetp && onetp.match(/(\d+)/);
    setOneTime(otp);
  };

  const handleDistSlider = (evt, value) => {
    setKms(value);
  };

  const getDistance = () => getMinDist(month) + (kms * 100);

  const kmMarks = useMemo(() => {
    const dist = getMinDist(month);

    return [{
      value: 0,
      label: `${dist} Kms`,
    },
    {
      value: 50,
      label: `${dist + 5000} Kms`,
    },
    {
      value: 100,
      label: `${dist + 10000} Kms`,
    }];
  }, [month]);

  const handleMonthSelect = (label) => { setMonth(label); setKms(0); };

  const createOneTimeMarks = useMemo(() => [0, 50, 100].map((value, index) => ({
    value,
    label: `Rs ${oneTimeFormula(totalPrice, index, priceList)}`,
  })), [priceList]);

  const getMonthLabel = (label) => `${label} month${label === 1 ? '' : 's'}`;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.sliders}
    >
      { false && (
        <>
          <Typography variant="h4" component="h3" gutterBottom>
            Pricing
          </Typography>
          <Grid item xs={12} sm={10}>
            <Typography variant="body2" align="justify" color="secondary" paragraph>
              <span className={classes.primText}>TYLT</span>
              {' '}
              plans are flexible and can vary based on your convenience.  Modify duration of usage, one time payment and km limit to find a plan that suits you.
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.slides}>
        <Typography variant="subtitle1" align="left" color="secondary">
          DURATION OF USAGE *
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {[12, 6, 3, 1].map((label) => (
            <Grid key={label} item xs={6} sm={3}>
              <Paper elevation={0} style={label === month ? { border: '3px solid #4fae61' } : null} className={classes.months} onClick={() => handleMonthSelect(label)}>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  {label === month && <CheckCircleRoundedIcon color="primary" />}
                  {getMonthLabel(label)}
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid container direction="row" justify="space-between" alignItems="flex-start" item xs={12} sm={10}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Monthly Fee
          </Typography>
          <Typography className={classes.primText} variant="h6" align="right" color="textSecondary" gutterBottom>
            &#x20b9;
            { ` ${priceFormat(amount - 1800)} `}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" item xs={12} sm={10}>
          <Typography variant="subtitle1" align="left" color="textSecondary" gutterBottom>
            Insurance & Maintenance
          </Typography>
          <Typography className={classes.primText} align="right" variant="h6" color="textSecondary" gutterBottom>
            &#x20b9;
            { ` ${priceFormat(1800)} `}
          </Typography>
        </Grid>
        <Divider style={{ width: '80%' }} />
        <br />
        <Grid container direction="row" justify="space-between" alignItems="flex-start" item xs={12} sm={10}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Total Fee
          </Typography>
          {offerPrice !== 0 && month === 1 ? (
            <Grid item>
              <Typography variant="h5" align="right" color="textSecondary" gutterBottom>
                <span className={classes.primText}>
                  <strike>
                    &#x20b9;
                    {` ${priceFormat(amount)} `}
                  </strike>
                </span>
              </Typography>
              <Typography variant="h4" align="right" color="textSecondary" gutterBottom>
                <Chip label="Special Offer" variant="outlined" style={{ marginRight: 16 }} />
                <span className={classes.primText}>
                  &#x20b9;
                  {` ${priceFormat(offerPrice)} `}
                </span>
                <span style={{ fontSize: 14 }}>per month</span>
              </Typography>
            </Grid>
          ) : (
            <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
              <span className={classes.primText}>
                &#x20b9;
                {` ${priceFormat(amount)} `}
              </span>
              <span style={{ fontSize: 14 }}>per month</span>
            </Typography>
          )}
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" item xs={12} sm={10}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Refundable deposit
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            <span className={classes.primText}>
              &#x20b9;
              {` ${priceFormat(5000)} `}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" item xs={12} sm={10}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Kilometer Limit
          </Typography>
          <Grid item>
            <Typography variant="h6" align="right" gutterBottom>
              <span className={classes.primText}>
                {` ${getMinDist(month)} Kms`}
              </span>
            </Typography>
            <Typography variant="body2" align="right" color="textSecondary" gutterBottom>
              (Extra mileage costs &#x20b9;
              {`${kmRate}/Km)`}
            </Typography>
          </Grid>
        </Grid>
        {false && (
          <>
            <Typography variant="h4" style={{ paddingLeft: 32 }} align="left" gutterBottom>
              +
            </Typography>
            <Typography variant="h5" align="left" gutterBottom className={classes.otpText}>
              <span className={classes.primText}>{`Rs ${priceFormat(gst ? oneTime * 1.18 : oneTime)} `}</span>
            </Typography>
            <Typography variant="subtitle1" align="left" color="textSecondary" className={classes.otpText}>
              ONE TIME PAYMENT
              {' '}
              <span className={classes.primText}>*</span>
            </Typography>
            <div className={classes.otpText}>
              <PriceSlider
                aria-label="one time payment"
                defaultValue={0}
                step={50}
                marks={createOneTimeMarks}
                onChange={handleOneTimeSlider}
              />
            </div>
            <Typography variant="body2" align="justify" color="textSecondary">
              <span className={classes.primText}>*</span>
              {' '}
              a one time payment needs to be made at the beginning of the subscription. This amount is not refundable.
            </Typography>
          </>
        )}
        { false && (
          <Grid item xs={2} sm={3}>
            <FormControlLabel
              value="top"
              control={(
                <Switch
                  checked={gst}
                  onChange={() => setGst(!gst)}
                  color="primary"
                />
              )}
              label="with GST"
              labelPlacement="top"
            />
          </Grid>
        )}
      </Grid>
      <br />
      { false && (
        <Grid item xs={10} className={classes.otpText}>
          <Typography variant="h5" align="left" gutterBottom>
            <span className={classes.primText}>{`${getDistance()} Kms`}</span>
          </Typography>
          <Typography variant="subtitle1" align="left" color="textSecondary">
            KM LIMIT
            {' '}
            <span className={classes.primText}>*</span>
          </Typography>
          <PriceSlider
            aria-label="kilometer limit"
            step={10}
            value={kms}
            marks={kmMarks}
            onChange={handleDistSlider}
          />
          <Typography variant="body2" align="justify" color="textSecondary">
            <span className={classes.primText}>*</span>
            {' '}
            km limit indicates the maximum kms the car can be driven within the subscription period. alter as per your needs.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

PaymentPage.propTypes = {
  totalPrice: PropTypes.number,
  kmRate: PropTypes.number,
  offerPrice: PropTypes.number,
  setSchedule: PropTypes.func,
};

export default PaymentPage;
