import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import Facebook from '@material-ui/icons/Facebook';
import LinkedIn from '@material-ui/icons/LinkedIn';
import YouTube from '@material-ui/icons/YouTube';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    color: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 3,
  },
  otherLinks: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
    paddingLeft: theme.spacing(3),
    cursor: 'pointer',
  },
  social: {
    margin: theme.spacing(0.7),
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        className={classes.otherLinks}
      >
        <Grid item xs={4} container direction="row" justify="flex-start">
          <Link color="inherit" href="/faq" className={classes.social}>
            FAQ
          </Link>
          <Link color="inherit" href="/earn" className={classes.social}>
            Earn with us
          </Link>
          <Link color="inherit" href="/how-it-works" className={classes.social}>
            How it works
          </Link>
          <Link color="inherit" href="/join" className={classes.social}>
            Join
          </Link>
          <Link color="inherit" href="/privacy" className={classes.social}>
            Privacy
          </Link>
          <Link color="inherit" href="/terms" className={classes.social}>
            Terms
          </Link>
          <Link color="inherit" href="/contact" className={classes.social}>
            Contact Us
          </Link>
        </Grid>
        <Grid item xs={8} container direction="row" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" align="center">
              {'Copyright © '}
              <Link color="inherit" href="/">
                Tylt
              </Link>
              {' '}
              {new Date().getFullYear()}
              {' | All Rights Reserved'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container direction="row" justify="center">
            <Link href="https://www.instagram.com/tyltcars/" color="inherit" target="_blank"><Instagram className={classes.social} /></Link>
            <Link href="https://twitter.com/TyltCars" color="inherit" target="_blank"><Twitter className={classes.social} /></Link>
            <Link href="https://www.facebook.com/tyltcars" color="inherit" target="_blank"><Facebook className={classes.social} /></Link>
            <Link href="https://www.linkedin.com/company/tylt-cars" color="inherit" target="_blank"><LinkedIn className={classes.social} /></Link>
            <Link href="https://www.youtube.com/channel/UCwZ---OemCP33u40wuMspNw" color="inherit" target="_blank"><YouTube className={classes.social} /></Link>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default withRouter(Footer);
