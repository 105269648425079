import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TuneIcon from '@material-ui/icons/Tune';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import NavBar from '../../components/NavBar';
import AddressForm from '../../components/AddressForm';
import KycForm from '../../components/KycForm';
import VehiclePage from '../VehiclePage';
import Assistance from '../../components/Assistance';
import Footer from '../../components/Footer';
import { withAuthorization } from '../../common/Session';
import { isObjEmpty } from '../../helpers/utility';
import { getUserProfile, updateUserProfile, makeIssueCall } from './actions';
import { timestamp } from '../../server/config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  layout: {
    overflow: 'hidden',
    flexGrow: 1,
    minHeight: '70vh',
  },
  terms: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    minWidth: 240,
    paddingTop: theme.spacing(2),
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  section: {
    paddingTop: theme.spacing(7),
  },
  toolbar: theme.mixins.toolbar,
  filterButton: {
    margin: theme.spacing(2, 0),
  },
  listItem: {
    marginLeft: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const accList = ['Profile', 'KYC', 'Vehicle', 'Assistance'];

function AccountPage(props) {
  const { user } = props;

  const classes = useStyles();
  const [openFilter, setFilterOpen] = useState(false);
  const [view, setView] = useState('Profile');
  const [openSnack, setOpenSnack] = useState(false);

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (user != null && isObjEmpty(account)) {
      dispatch(getUserProfile(user.uid));
    }
  }, [user]);

  const onProfileSubmit = (data) => {
    data.primaryPhone = user.phoneNumber;
    dispatch(updateUserProfile(data, user.uid));
    setOpenSnack(true);
  };

  const onKycSubmit = (data) => {
    dispatch(updateUserProfile({ kyc: data, updatedAt: timestamp }, user.uid));
    setOpenSnack(true);
  };

  const assistCall = (data) => {
    data.createdAt = timestamp;
    data.status = 'open';
    data.userId = user.uid;
    data.primaryPhone = user.phoneNumber;
    dispatch(makeIssueCall(data));
  };


  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const getView = () => {
    switch (view) {
      case 'Profile':
        return <AddressForm onSubmit={onProfileSubmit} data={account} />;
      case 'KYC':
        return <KycForm onSubmit={onKycSubmit} data={account} />;
      case 'Vehicle':
        return <VehiclePage vehicleId={account.vehicleId} />;
      default:
        return <Assistance assistCall={assistCall} />;
    }
  };

  const accDrawer = () => (
    <List>
      {accList.map((item) => (
        <ListItem key={item} button onClick={() => { setView(item); handleFilterClose(); }}>
          <ListItemText inset primary={item} />
        </ListItem>
      ))}
      <Divider />
      <ListItem className={classes.listItem}>
        <ListItemIcon><AccountBalanceWallet fontSize="large" color="primary" /></ListItemIcon>
        <ListItemText primary={`₹ ${account.balance || 0} /-`} primaryTypographyProps={{ variant: 'h6' }} />
      </ListItem>
      <Divider />
    </List>
  );

  return (
    <>
      <NavBar />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.root}
      >
        <Hidden smDown>
          <Grid item md={2}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              {accDrawer()}
            </Drawer>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={10} className={classes.layout}>
          <Hidden mdUp>
            <Grid container justify="center">
              <Button variant="outlined" className={classes.filterButton} onClick={() => setFilterOpen(true)}>
                <TuneIcon className={classes.leftIcon} />
                Sections
              </Button>
            </Grid>
          </Hidden>
          {getView()}
          <Footer />
        </Grid>
      </Grid>
      <Hidden smUp implementation="css">
        <Dialog
          fullScreen
          open={openFilter}
          onClose={handleFilterClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="lg"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DialogTitle id="responsive-dialog-title" disableTypography className={classes.title}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleFilterClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {accDrawer()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFilterClose} color="primary">
              Close
            </Button>
            <Button onClick={handleFilterClose} color="primary" autoFocus>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
      >
        <SnackbarContent
          message="Profile updated!"
        />
      </Snackbar>
    </>
  );
}

AccountPage.propTypes = {
  user: PropTypes.object,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
