import { UPDATE_VEHICLE, HYDRATE_VEHICLE } from '../../config/constants';

const initialState = {};

export default function reducer(state = initialState, action) {
  const {
    type, data, key, value,
  } = action;

  switch (type) {
    case HYDRATE_VEHICLE:
      return Object.assign({}, state, data);
    case UPDATE_VEHICLE:
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
}
