import { getFilteredData } from '../../server/database';
import { getPriceQuery } from '../../helpers/utility';
import {
  RESET_PRODUCT_LIST_PAGE, UPDATE_MODEL_LIST_FILTERS,
  UPDATE_PRODUCT_LIST_CARS, ADD_PRODUCT_LIST_LASTDOC,
  REMOVE_PRODUCT_LIST_CARS, REMOVE_PRODUCT_LIST_FILTERALL,
  ADD_PRICE_FILTER, REMOVE_PRICE_FILTER, ADD_PRODUCT_LIST_FILTER,
  REMOVE_PRODUCT_LIST_FILTER, REMOVE_PRODUCT_LIST_CAR_MAKE,
  UPDATE_PRODUCT_LIST_CARS_ALL, APPEND_PRODUCT_LIST_CARS_ALL,
  UPDATE_PRODUCT_LIST_MAKE_FILTER, NO_MORE_CARS,
} from '../../config/constants';

export function resetPLP() {
  return {
    type: RESET_PRODUCT_LIST_PAGE,
  };
}

export function updatePLPCars(data, mainKey, query) {
  return {
    type: UPDATE_PRODUCT_LIST_CARS, data, mainKey, subKey: query.filterValue, query,
  };
}

export function updatePLPMakeFilter(mainKey, toggle) {
  return {
    type: UPDATE_PRODUCT_LIST_MAKE_FILTER, mainKey, toggle,
  };
}

export function updatePLPCarsAll(data, query, mainKey, toggle) {
  return {
    type: UPDATE_PRODUCT_LIST_CARS_ALL, data, query, mainKey, toggle,
  };
}

export function appendPLPCarsAll(data) {
  return {
    type: APPEND_PRODUCT_LIST_CARS_ALL, data,
  };
}

export function addProductListFilter(mainKey, subKey, query) {
  return {
    type: ADD_PRODUCT_LIST_FILTER, mainKey, subKey, query,
  };
}

export function removeProductListFilter(mainKey, subKey) {
  return {
    type: REMOVE_PRODUCT_LIST_FILTER, mainKey, subKey,
  };
}

export function removeProductListFilterAll(mainKey) {
  return {
    type: REMOVE_PRODUCT_LIST_FILTERALL, mainKey,
  };
}

export function updatePLPLastDoc(lastVisible) {
  return {
    type: ADD_PRODUCT_LIST_LASTDOC, lastVisible,
  };
}

export function addPriceFilter(slideValue, subQuery) {
  return {
    type: ADD_PRICE_FILTER, slideValue, subQuery,
  };
}

export function removePriceFilter(subKey) {
  return {
    type: REMOVE_PRICE_FILTER, subKey,
  };
}

export function removePLPCarMake(mainKey) {
  return {
    type: REMOVE_PRODUCT_LIST_CAR_MAKE, mainKey,
  };
}

export function removePLPCars(mainKey, subKey) {
  return {
    type: REMOVE_PRODUCT_LIST_CARS, mainKey, subKey,
  };
}

export function updateMakeModelFilters(mainKey, subKey, toggle) {
  return {
    type: UPDATE_MODEL_LIST_FILTERS, mainKey, subKey, toggle,
  };
}

function noMoreCars() {
  return {
    type: NO_MORE_CARS,
  };
}

export function updateOtherFilters(mainKey, subKey, toggle) { // other than make & price
  return (dispatch) => {
    dispatch(removeProductListFilterAll(mainKey));
    if (toggle) {
      const query = {
        key: subKey, field: mainKey, operator: '==', filterValue: subKey,
      };
      dispatch(addProductListFilter(mainKey, subKey, query));
    } else dispatch(removeProductListFilter(mainKey, subKey));
    dispatch(fireAllQueries());
  };
}

export function setPriceFilter(mainKey, slideValue) { // price
  return (dispatch) => {
    const [low, high] = slideValue;
    if (low === 0) {
      dispatch(removePriceFilter('low'));
    }
    if (high === 100) {
      dispatch(removePriceFilter('high'));
    }
    const query = getPriceQuery(mainKey, slideValue);
    dispatch(addPriceFilter(slideValue, query));
    dispatch(fireAllQueries());
  };
}

export function loadMorePLPCars() {
  console.log('load more fired!');
  return (dispatch, getState) => {
    const { query, subQueries, lastVisible } = getState().productListing; // models and makes inside queries
    if (query.filterValue.length === 0) {
      return getFilteredData('carProfiles', subQueries, 9, lastVisible).then(
        ([data, lastVisib]) => {
          if (data.length === 0) { dispatch(noMoreCars()); }
          dispatch(appendPLPCarsAll(data));
          dispatch(updatePLPLastDoc(lastVisib));
        },
        (error) => console.log(error)
      );
    }
    return getFilteredData('carProfiles', [query, ...subQueries], 9, lastVisible).then(
      ([data, lastVisib]) => {
        if (data.length === 0) { dispatch(noMoreCars()); }
        dispatch(appendPLPCarsAll(data));
        dispatch(updatePLPLastDoc(lastVisib));
      },
      (error) => console.log(error)
    );
  };
}

export function fireAllQueries(makeName, toggle) {
  console.log('plp call fired!');
  return (dispatch, getState) => {
    const { subQueries, query } = getState().productListing; // models and makes inside queries
    const tempQuery = Object.assign({}, query);

    if (toggle) {
      tempQuery.filterValue = [...tempQuery.filterValue, makeName];
    } else if (toggle === false) {
      tempQuery.filterValue = tempQuery.filterValue.filter((item) => item !== makeName);
    }

    if (makeName) dispatch(updatePLPMakeFilter(makeName, toggle));

    if (tempQuery.filterValue.length === 0) {
      return getFilteredData('carProfiles', subQueries).then(
        ([data, lastVisible]) => {
          dispatch(updatePLPCarsAll(data, tempQuery));
          dispatch(updatePLPLastDoc(lastVisible));
        },
        (error) => console.log(error)
      );
    }
    return getFilteredData('carProfiles', [tempQuery, ...subQueries]).then(
      ([data, lastVisible]) => {
        dispatch(updatePLPCarsAll(data, tempQuery));
        dispatch(updatePLPLastDoc(lastVisible));
      },
      (error) => console.log(error)
    );
  };
}

export function fireMakeModelQuery(makeName, queryData) {
  console.log('makeModel fired!');
  return (dispatch, getState) => {
    const queryList = getState().productListing.queries[makeName] || {};
    const { subQueries } = getState().productListing;
    return Promise.all(
      queryData
        .filter((query) => !(query.filterValue in queryList))
        .map((query) => getFilteredData('carProfiles', [query, ...subQueries]).then(
          ([data, lastVisible]) => { dispatch(updatePLPCars(data, makeName, query)); dispatch(updatePLPLastDoc(lastVisible)); },
          (error) => console.log(error)
        ))
    );
  };
}
