import {
  UPDATE_ACCOUNT, HYDRATE_ACCOUNT,
} from '../../config/constants';
import { getDocData, setDocData, addData } from '../../server/database';

export function hydrateAccount(data) {
  return {
    type: HYDRATE_ACCOUNT, data,
  };
}

export function updateAccount(key, value) {
  return {
    type: UPDATE_ACCOUNT, key, value,
  };
}

export function getUserProfile(uid) {
  console.log('profile call fired');
  return (dispatch) => {
    getDocData('users', uid).then(
      (data) => dispatch(hydrateAccount(data)),
      (error) => console.log(error)
    );
  };
}

export function updateUserProfile(data, uid) {
  console.log('update user fired');
  return (dispatch) => {
    setDocData('users', uid, data).then(
      (value) => value === 'Success' && dispatch(hydrateAccount(data)),
      (error) => console.log(error)
    );
  };
}

export function makeIssueCall(data) {
  console.log('issue call fired');
  return () => {
    addData('issues', data).then(
      (value) => console.log(value),
      (error) => console.log(error)
    );
  };
}
