import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { labels } from '../../helpers/translations';
import { getLabel, getCarName } from '../../helpers/utility';
import ExpandedFilterCard from '../ExpandedFilterCard';
import useDebounce from '../../helpers/debounce';

const useStyles = makeStyles((theme) => ({
  expansion: {
    width: '100%',
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.background.default,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
  },
  formControlLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  details: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  subscribePrice: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const AirbnbSlider = withStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.primary.dark,
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -10,
    marginLeft: -13,
    boxShadow: '#ebebeb 0px 2px 2px',
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
}))(Slider);

function FilterPane(props) {
  const {
    name, filter, setFilters, removeCarModel, updateMakeModel, setMakeModelQuery, setPriceFilter, removeCarMake,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [makeName] = React.useState('');
  const [price, setPrice] = React.useState([filter.low, filter.high]);
  const debouncedPrice = useDebounce(price, 2000);
  const didMountRef = useRef(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (debouncedPrice && didMountRef.current) {
      setPriceFilter(name, debouncedPrice);
    } else didMountRef.current = true;
  }, [debouncedPrice]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (subKey) => (event) => {
    setFilters(name, subKey, event.target.checked);
  };

  function valueLabelFormat(value) {
    return `₹${value * 300}${value === 100 ? '+' : ''}`;
  }

  // const isChecked = (cars) => Object.keys(cars).some((el) => cars[el]);

  const filterComponent = (filterField) => {
    switch (filterField) {
      case 'make': return (
        <ExpansionPanelDetails className={classes.details}>
          <FormControl>
            <FormGroup>
              {Object.keys(filter).map((item) => (
                <FormControlLabel
                  key={item}
                  control={<Checkbox color="primary" checked={filter[item]} onChange={handleChange(item)} value={item} />}
                  label={<Typography className={classes.formControlLabel}>{getCarName(item, true)}</Typography>}
                />
              ))}
            </FormGroup>
          </FormControl>
        </ExpansionPanelDetails>
      );

      case 'subscribePrice': return (
        <ExpansionPanelDetails className={classes.subscribePrice}>
          <AirbnbSlider
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="on"
            step={5}
            aria-labelledby="range-slider"
            value={price}
            onChange={(event, newValue) => setPrice(newValue)}
          />
        </ExpansionPanelDetails>
      );
      default:
        return (
          <ExpansionPanelDetails className={classes.details}>
            <FormControl>
              <FormGroup>
                {Object.keys(filter).map((item) => (
                  <FormControlLabel
                    key={item}
                    control={<Checkbox color="primary" checked={filter[item]} onChange={handleChange(item)} value={item} />}
                    label={<Typography className={classes.formControlLabel}>{getLabel(item)}</Typography>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </ExpansionPanelDetails>
        );
    }
  };

  return (
    <>
      <ExpansionPanel elevation={0} className={classes.expansion} defaultExpanded={name === 'subscribePrice'}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={labels[name]}
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{labels[name]}</Typography>
        </ExpansionPanelSummary>
        {filterComponent(name)}
      </ExpansionPanel>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <ExpandedFilterCard
          makeName={makeName}
          handleClose={handleClose}
          modelList={filter[makeName]}
          setFilters={setFilters}
          removeCarModel={removeCarModel}
          updateMakeModel={updateMakeModel}
          setMakeModelQuery={setMakeModelQuery}
          removeCarMake={removeCarMake}
        />
      </Dialog>
    </>
  );
}


FilterPane.propTypes = {
  name: PropTypes.string,
  filter: PropTypes.object,
  setFilters: PropTypes.func,
  setMakeModelQuery: PropTypes.func,
  updateMakeModel: PropTypes.func,
  removeCarModel: PropTypes.func,
  removeCarMake: PropTypes.func,
  setPriceFilter: PropTypes.func,
};

export default FilterPane;
